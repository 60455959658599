import * as Yup from 'yup';

export const AddMarketValidation = Yup.object().shape({
  name: Yup.string()
    .required('Market name is required'),

  // flag: Yup.mixed()
  //   .required('Flag image is required')
  //   // .test('fileSize', 'File size is too large', (value) => {
  //   //   return value && value.size <= 5 * 1024 * 1024; // 5MB max file size
  //   // })
  //   .test('fileType', 'Unsupported file format', (value) => {
  //     return value && ['image/jpeg', 'image/png', 'image/gif'].includes(value.type);
  //   }),

  currencyName: Yup.string()
    .required('Currency name is required'),

  currencySymbol: Yup.string()
    .required('Currency symbol is required'),

    status: Yup.number()
    .oneOf([0, 1], 'Status must be either 1 (Inactive) or 0 (Active)')
    .required('Status is required'),
});
