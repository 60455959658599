import axiosInstance from "../utils/axiosInstance";




const API_URL = `${process.env.REACT_APP_API_URL}/api/admin/market`;




const getmarket = async (status) => {
    const res = await axiosInstance.get(API_URL + "/",{
        params: {
            status:status
        }
    })

    return res.data;

}


const getmarketByid = async (id) => {
    const res = await axiosInstance.get(API_URL + `/${id}/getmarket`)

    return res.data;

}

const saveMarket = async (data) => {
    try {
        // console.log(data);
        const res = await axiosInstance.post(API_URL + "/AddMarket", data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return res.data;
    } catch (err) {
        console.log("ERROR ------->", err);
        // Check for specific MongoDB error codes and provide a more readable message
        // if (err.response && err.response.data && err.response.data.code === "E11000") {
        //     throw new Error(`Category name already exists.`);
        // }
        throw err;
    }
}


const EditMarket = async (data) => {
    try {
        // console.log(data);
        const res = await axiosInstance.patch(API_URL + "/EditMarket", data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return res.data;
    } catch (err) {
        console.log("ERROR ------->", err);
        // Check for specific MongoDB error codes and provide a more readable message
        // if (err.response && err.response.data && err.response.data.code === "E11000") {
        //     throw new Error(`Category name already exists.`);
        // }
        throw err;
    }
}




const MarketService={
    getmarket,saveMarket,getmarketByid,EditMarket
}


export default MarketService;




