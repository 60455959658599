import React, { useEffect, useState } from "react";
import Layout from "../components/layouts/Layout";

import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { startLoading, stopLoading } from "../features/loading/loadingSlice"; // Import Redux actions for loading
import {
  changePassword,
  getProfilePicture,
  uploadProfilePicture,
} from "../services/profile.service";
import { useNavigate } from "react-router-dom";
import { logout } from "../features/auth/authSlice";

const ProfilePage = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth); // Accessing user data from Redux
  const { isLoading } = useSelector((state) => state.loading);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [selectedImage, setSelectedImage] = useState(null); // State for uploaded image
  const [profilePic, setProfilePic] = useState(null); // State for the current profile picture filename

  // Fetch profile picture on component mount
  useEffect(() => {
    const fetchProfilePic = async () => {
      try {
        if (user?.user?.id) {
          const profilePicFilename = await getProfilePicture(user.user.id);
          setProfilePic(profilePicFilename); // Set the profile picture filename
        }
      } catch (error) {
        console.error("Error fetching profile picture:", error);
      }
    };

    fetchProfilePic();
  }, [user]);

  const handlePasswordChange = async () => {
    if (!password || !confirmPassword) {
      toast.error("Please fill in all fields");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    try {
      dispatch(startLoading());
      await changePassword(user.user?.id, password);
      toast.success("Password changed successfully");
      setPassword("");
      setConfirmPassword("");
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to change password");
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      toast.error("No file selected");
      return;
    }

    // Validate file type
    if (!file.type.startsWith("image/")) {
      toast.error("Only image files are allowed");
      return;
    }

    // Set the selected image for preview
    setSelectedImage(file);
  };

  const handleUpload = async () => {
    if (!selectedImage) {
      toast.error("No image selected");
      return;
    }

    try {
      dispatch(startLoading());

      // Call the upload API
      const response = await uploadProfilePicture(user.user?.id, selectedImage);
      toast.success("Profile picture updated successfully");

      // Update the profile picture state after successful upload
      setProfilePic(response.profile_pic);
      setSelectedImage(null);
    } catch (error) {
      console.error("Error uploading profile picture:", error);
      toast.error(
        error.response?.data?.message || "Failed to upload profile picture"
      );
    } finally {
      dispatch(stopLoading());
    }
  };

  const profilePicUrl = profilePic
    ? `${process.env.REACT_APP_MEDIA_URL}/profile/${profilePic}`
    : null;

  const previewUrl = selectedImage
    ? URL.createObjectURL(selectedImage)
    : profilePicUrl;

  const username = user?.user?.name || "User Name";
  const email = user?.user?.email || "user@example.com";

  const { user: currentUser } = useSelector((state) => state.auth) || {};
  const navigate = useNavigate();
  const handleLogout = async (event) => {
    event.preventDefault();
    const refreshToken = currentUser?.tokens?.refresh?.token;
    dispatch(logout(refreshToken)).then((result) => {
      if (result) {
        navigate("/login");
      }
    });
  };
  return (
    <Layout>
      <div
        className="profile-wrapper"
        style={{
          padding: "20px",
          maxWidth: "1500px",
          margin: "0 auto",
          // backgroundColor: "#f9f9f9",
          // borderRadius: "8px",
          // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          // fontFamily: "Arial, sans-serif",
          fontFamily: "Bricolage Grotesque, sans-serif ",
        }}
      >
        {/* Header Section */}
        <div
          className=" Header-section"
          style={{
            marginBottom: "30px",
            textAlign: "center",
            padding: "30px",
            // border: `1px solid #F8BBD0`,
            borderRadius: "8px",
            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h2 style={{ color: "dark", fontWeight: "bold" }}>{username}</h2>
          <p
            style={{
              color: "#6c757d",
              fontStyle: "italic",
              marginBottom: "15px",
            }}
          >
            Logged in with {email}
          </p>
          {/* <button
            style={{
              marginBottom: "10px",
              padding: "10px 20px",
              fontWeight: "bold",
              border: `2px solid #E91E63`,
              backgroundColor: "transparent",
              color: "#E91E63",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onMouseOver={(e) =>
              (e.target.style.backgroundColor = "#F8BBD0")
            }
            onMouseOut={(e) =>
              (e.target.style.backgroundColor = "transparent")
            }
            onClick={handleLogout}
          >
            Logout
          </button> */}

          <button
            className="btn btn-outline-primary"
            onMouseOver={(e) => (e.target.style.backgroundColor = "#F8BBD0")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "transparent")}
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>

        {/* Content Section */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
            flexWrap: "wrap",
          }}
        >
          {/* Change Password Section */}
          <div
            style={{
              flex: "1",
              padding: "20px",
              // border: 1px solid #F8BBD0,
              borderRadius: "8px",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h5 className="text-primary" style={{ fontWeight: "semi-bold" }}>
              Change Password
            </h5>
            <input
              type="password"
              placeholder="New Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control mb-3"
              // style={{
              //   width: "100%",
              //   padding: "10px",
              //   margin: "10px 0",
              //   border: "1px solid #ccc",
              //   borderRadius: "4px",
              // }}
            />
            <input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
               className="form-control mb-3"
              // style={{
              //   width: "100%",
              //   padding: "10px",
              //   margin: "10px 0",
              //   border: "1px solid #ccc",
              //   borderRadius: "4px",
              // }}
            />
            {/* <button
              style={{
                width: "100%",
                padding: "10px",
                fontWeight: "bold",
                backgroundColor: isLoading ? "#F8BBD0" : "#E91E63",
                color: isLoading ? "#333" : "#fff",
                border: "none",
                borderRadius: "4px",
                cursor: isLoading ? "not-allowed" : "pointer",
              }}
              onClick={handlePasswordChange}
              disabled={isLoading}
            >
              {isLoading ? "Please wait..." : "Change Password"}
            </button> */}
            <button
              className="btn btn-primary "
              onClick={handlePasswordChange}
              disabled={isLoading}
            >
              {isLoading ? "Please wait..." : "Change Password"}
            </button>
          </div>

          {/* Upload Profile Picture Section */}
          <div
            style={{
              flex: "1",
              padding: "20px",
              // border: 1px solid #F8BBD0,
              borderRadius: "8px",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h5
              className="card-title text-primary"
              style={{ fontWeight: "semi-bold" }}
            >
              Upload Profile Picture
            </h5>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "20px 0",
              }}
            >
              <img
                src={previewUrl}
                alt="Profile"
                style={{
                  width: "120px",
                  height: "120px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                }}
              />
            </div>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ marginBottom: "10px" }}
            />
            {/* <button
              onClick={handleUpload}
              disabled={isLoading}
              style={{
                width: "100%",
                padding: "10px",
                backgroundColor: isLoading ? "#ccc" : "#E91E63",
                color: "#fff",
                border: "none",
                borderRadius: "4px",
              }}
            >
              {isLoading ? "Uploading..." : "Change Pic"}
            </button> */}
            <div className="w-100">
              <button
                className="btn btn-primary mt-2"
                onClick={handleUpload}
                disabled={isLoading}
              >
                {isLoading ? "Uploading..." : "Change Pic"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProfilePage;
