import React, { useEffect, useState } from 'react'
import Layout from '../../components/layouts/Layout'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchReturnsById, UpdateReturnStatus } from '../../features/Return/returnSlice';
import { useDispatch } from 'react-redux';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDateUAE } from '../../utils/formatDate';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { validationSchema } from '../../validations/returnsValidation';
import { Formik, Form, Field, ErrorMessage } from 'formik';

const Details = () => {

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [returnDetails, setreturnDetails] = useState([])
  const [newStatus, setNewStatus] = useState('PENDING');
  const [reason, setReason] = useState('');
  console.log(returnDetails)
  const returnDeails = useSelector((state) => state.return.Returndata);


  const fetchReturnByID = async () => {

    try {
      const res = await dispatch(fetchReturnsById(id)).unwrap();
      setreturnDetails(res)
      console.log(res)

    } catch (err) {
      console.log(err)
    }
  }


  useEffect(() => {
    fetchReturnByID()
  }, [id])





  const initialValues = {
    status: '',
    description: '',
  };



  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const data = {
      status: values.status,
      description: values.description,
    };

    try {
      await dispatch(UpdateReturnStatus({ id, data })).unwrap();
      fetchReturnByID(); // Refresh data after submission
      toast.success('Status updated successfully!');
      setSubmitting(false);
      resetForm();
    } catch (err) {
      console.error('Error updating return status:', err);
      setSubmitting(false);
    }
  };


  // Get current statuses from status logs
  const currentStatuses = returnDetails.statusLogs
    ? returnDetails.statusLogs.map((log) => log.status)
    : [];

  // All possible status options
  const allStatuses = ['PENDING', 'APPROVED', 'DENIED', 'RETURNED', 'REFUNDED'];

  // Filter out already existing statuses from the dropdown options
  const availableStatuses = allStatuses.filter((status) => !currentStatuses.includes(status));

  return (
    <Layout>
      <div className="col-12 stretch-card container-fluid">

        {returnDetails && (
          <>
            <button className="btn btn-link mb-3" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faArrowLeft} className='me-1' /> Back
            </button>

            <div className='row align-center'>
              <div className='col-6'>
                <div className="d-flex align-items-center">
                  <h2 className='heading ms-3'> Order ID: #{returnDeails.order_id && returnDeails.order_id.order_no} </h2>
                  <div class="alert alert-warning py-1 fw-bolder ms-4 mb-0 fs-xs" >
                    {returnDetails.status}
                  </div>
                </div>


                <div className='d-flex gap-3 justify-between align-center ms-3 pb-5'>

                  <div className=''>Order created date: {formatDateUAE(returnDetails.order_id && returnDetails.order_id.createdAt)} </div>


                </div>

                {/* <div className='d-flex gap-1 justify-between align-center ms-3 pb-1'>


                  <div className=''>return status: {returnDetails.status} </div>

                </div> */}



              </div>


            </div>



            <div className="col-12 stretch-card container-fluid">
              <div className="row">

                <div className="col-md-5">
                  <div className='card mb-4'>

                    <div className='card-body'>

                      <h5 className='fw-bolder mb-3'> Returns Items</h5>
                      <table className='table'>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Qty</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{returnDetails.item && returnDetails.item.name}</td>
                            <td> {returnDetails.return_qty}</td>
                          </tr>
                        </tbody>
                      </table>
                      {/* <ul className="list-group list-group-flush">
                        
                        <li className="list-group-item px-0" >
                          <div className="d-flex justify-content-between align-items-center">
                         

                              <h5>{returnDetails.item && returnDetails.item.name}  </h5> ( Returned: {returnDetails.return_qty})
                          

                          </div>
                        </li>

                      </ul> */}

                    </div>

                  </div>
                  <div className="card" >
                    <div className="card-body">
                      <h5 className='fw-bolder mb-4'>Update Return Status</h5>

                      {/* Formik Form */}
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                      >
                        {({ isSubmitting }) => (
                          <Form>
                            {/* Status Dropdown */}
                            <div className="form-group mb-3">
                              <label htmlFor="statusSelect" className="form-label">Return Status</label>
                              <Field
                                as="select"
                                id="statusSelect"
                                name="status"
                                className="form-select"
                              >
                                <option value="" disabled>
                                  Select Status
                                </option>
                                {availableStatuses.map((status, index) => (
                                  <option key={index} value={status}>
                                    {status}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage name="status" component="div" className="text-danger" />
                            </div>

                            {/* Reason Textarea */}
                            <div className="form-group mb-3">
                              <label htmlFor="reasonInput" className="form-label">Reason</label>
                              <Field
                                as="textarea"
                                id="reasonInput"
                                name="description"
                                className="form-control"
                                rows="3"
                                placeholder="Enter the reason for the status update"
                              />
                              <ErrorMessage name="description" component="div" className="text-danger" />
                            </div>

                            {/* Submit Button */}
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={isSubmitting}
                            >
                              Submit
                            </button>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>

                </div>
                <div className="col-md-7">
                  {/* Return Logs Section */}
                  <div className='card'>
                    <div className='card-body'>
                    <div className="order-status-logs ">
                    <h5 className='fw-bolder mb-3'>Return Logs</h5>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Date & Time</th>
                            <th>Status</th>
                            <th>Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {returnDetails.statusLogs && returnDetails.statusLogs.map((log, index) => {

                            const dateTimeString = `${formatDateUAE(log.timestamp)}`; // Merged Date and Time

                            return (
                              <tr key={index}>
                                <td>{dateTimeString}</td> {/* Combined Date & Time */}
                                <td>{log.status}</td>
                                <td>{log.description ? log.description : 'No details available'}</td> {/* Modify details logic based on available data */}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                    </div>
                  </div>
                 
                </div>

              </div>


            </div>









          </>
        )}



      </div>

    </Layout>
  )
}

export default Details
