import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import DataTable from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { selectLowStockItems } from '../features/inventory/inventorySlice';

const LowStockTable = () => {
    const navigate = useNavigate(); // Initialize the navigate function
    const lowStockItems = useSelector(selectLowStockItems);

    const data = lowStockItems.flatMap(product => {
        const variants = product.product_variants.length > 0
            ? product.product_variants
                .filter(variant => variant.stock < 20)
                .map(variant => ({
                    productId: product._id,
                    productName: `${product.name} - ${variant.name}`,
                    variantId: variant._id,
                    variantName: variant.name,
                    sku: variant.sku,
                    stock: variant.stock,
                }))
            : [];

        const noVariantProduct = product.stock < 20 && product.product_variants.length === 0
            ? [{
                productId: product._id,
                productName: product.name,
                variantId: null,
                sku: product.sku,
                stock: product.stock,
            }]
            : [];

        return [...variants, ...noVariantProduct];
    });

    const columns = [
        {
            name: 'Product Name',
            selector: row => <b>{row.variantId ? `${row.productName} - ${row.variantName}` : row.productName}</b>,
            sortable: true,
            grow: 2.5,
        },
        {
            name: 'SKU',
            selector: row => row.sku,
            sortable: true,
        },
        {
            name: 'Stock',
            cell: row => row.stock,
            sortable: true,
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '72px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px',
                paddingRight: '8px',
            },
        },
        cells: {
            style: {
                paddingLeft: '8px',
                paddingRight: '8px',
            },
        },
    };

    const handleRowClick = (row) => {
        navigate(`/editproduct/${row.productId}`); // Navigate to the edit category page with the product ID
    };

    return (
        <div className="card mb-4">
            <div className="card-body">
                <h5 className="card-title text-primary">Low Stock Items (under 20 units)</h5>
                <DataTable
                    columns={columns}
                    data={data}
                    customStyles={customStyles}
                    pagination
                    highlightOnHover
                    pointerOnHover
                    responsive
                    dense
                    onRowClicked={handleRowClick} // Add row click handler
                />
            </div>
        </div>
    );
};

export default LowStockTable;
