import ImageUploader from "../../../components/imageUploader";

const EditFileInput = ({ field,filenameid, form, ...props }) => {
  const handleChange = (event) => {
    const file = event.currentTarget.files[0];
    form.setFieldValue(field.name, file);
  };
 console.log(filenameid)
  return (
    // <input
    //   type="file"
      
    //   onChange={handleChange}
    //   {...props}
    // />
    <ImageUploader  setFieldValue={form.setFieldValue}  imageType={field.name} fileType={filenameid} />
  );
};

export default EditFileInput  