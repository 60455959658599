import React, { useEffect, useState } from 'react';
import Layout from '../../components/layouts/Layout';
import { FaArrowLeft } from "react-icons/fa";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { useNavigate, useParams } from 'react-router-dom';
import { AddMarketValidation } from '../../validations/addMarketValidation'; // Add your validation schema for AddMarket
import { createMarket, fetchMarketById, UpdateMarketById } from '../../features/market/marketSlice';


const AddMarket = () => {
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [existingFlag, setExistingFlag] = useState(null); // For flag preview
  const [flagRemoved, setFlagRemoved] = useState(false);  // To track if the flag was removed

  const [initialValues, setInitialValues] = useState({
    name: '',
    flag: null,
    currencyName: '',
    currencySymbol: '',
    status: 0,
  });


  console.log(initialValues)


  //   const initialValues = {
  //     name: '',
  //      flag: null,
  //     currencyName: '',
  //     currencySymbol: '',
  //     status: 0,
  //   };



  //edit


  // Check if it's in edit mode and fetch the market details
  useEffect(() => {
    if (id) {
      setIsEdit(true);
      const fetchMarket = async () => {
        try {
          const market = await dispatch(fetchMarketById(id)).unwrap();
          console.log(market)
          setInitialValues({
            name: market.data.name,
            flag: null,
            currencyName: market.data.currency.name,
            currencySymbol: market.data.currency.symbol,
            status: market.data.status === 1 ? 1 : 0,
          });
          setExistingFlag(market.data.flag);
        } catch (err) {
          console.log(err)
        }


      };
      fetchMarket();
    }
  }, [id, dispatch]);





  const handleSubmit = async (values) => {
    const updatedValues = { ...values };
    try {
      if (isEdit) {
        updatedValues.id = id;

        const res = await dispatch(UpdateMarketById({ ...updatedValues })).unwrap();
        if (res) {
          toast.success("Market updated successfully!");
          navigate("/market");
        }


      } else {
        const res = await dispatch(createMarket(updatedValues)).unwrap();
        if (res) {
          toast.success("Market created successfully!");
          navigate("/market");
        }

      }
    } catch (err) {
      console.log(err)
    }


  };


  const goBack = () => {
    window.history.back();
  };

  return (
    <Layout>
      <div className="col-12 stretch-card container-fluid">
        <div style={{ marginBottom: '30px', display: "flex", alignItems: "center", gap: "20px", color: '#D93D6E' }}>
          <FaArrowLeft size={20} cursor="pointer" onClick={goBack} />
          <h2 className="heading">{isEdit ? 'Edit Market' : 'Add Market'}</h2>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={AddMarketValidation}
          enableReinitialize={true}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              <div className="card">
                <div className="card-body">
                  <div className='row mb-4'>
                    <div className='col-md-6'>
                      {/* Name */}
                      <div className="form-group">
                        <label htmlFor="name" className="form-label">Market Name</label>
                        <Field
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          disabled={isEdit}
                        />
                        <ErrorMessage name="name" component="small" className="text-danger" />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      {/* Flag */}

                      <div className="form-group">
                        <label htmlFor="flag" className="form-label">Flag Image</label>

                        {/* Show existing flag if present and not removed */}
                        {existingFlag && !flagRemoved && (
                          <div >
                            <img
                              src={`${process.env.REACT_APP_MEDIA_URL}${existingFlag}`}
                              alt="flag preview"
                              width="45"
                            />
                            <button
                              type="button"
                              className="btn btn-default text-decoration-underline text-danger ms-2"
                              onClick={() => {
                                setFlagRemoved(true);
                                setFieldValue('flag', null); // Remove the flag in Formik
                              }}
                            >
                              Remove Flag
                            </button>
                          </div>
                        )}

                        {/* File input enabled only when the flag is removed */}
                        {(!existingFlag || flagRemoved) && (
                          <input
                            type="file"
                            className="form-control"
                            id="flag"
                            name="flag"
                            accept="image/*"
                            onChange={(event) => {
                              setFieldValue('flag', event.currentTarget.files[0]);
                            }}
                          />
                        )}

                        <ErrorMessage name="flag" component="small" className="text-danger" />
                      </div>
                    </div>
                  </div>
                  <div className='row mb-4'>
                    <div className='col-md-6'>
                      {/* Currency Name */}
                      <div className="form-group">
                        <label htmlFor="currencyName" className="form-label">Currency Name</label>
                        <Field
                          type="text"
                          className="form-control"
                          id="currencyName"
                          name="currencyName"
                        />
                        <ErrorMessage name="currencyName" component="small" className="text-danger" />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      {/* Currency Symbol */}
                      <div className="form-group">
                        <label htmlFor="currencySymbol" className="form-label">Currency Symbol</label>
                        <Field
                          type="text"
                          className="form-control"
                          id="currencySymbol"
                          name="currencySymbol"
                        />
                        <ErrorMessage name="currencySymbol" component="small" className="text-danger" />
                      </div>
                    </div>
                  </div>
                  {/* Status */}
                  <div className="form-group">
                    <div className="form-check">
                      <Field
                        type="checkbox"
                        className="form-check-input"
                        id="active"
                        name="status"
                        checked={values.status === 1}
                        onChange={() => {
                          setFieldValue('status', values.status === 1 ? 0 : 1);
                        }}
                      />
                      <label className="form-check-label ms-1" htmlFor="active">
                        Set as Active
                      </label>
                    </div>
                  </div>

                </div>
                <div className='card-footer mb-3'>
                <button
                  className="btn py-8 fs-4  rounded-2"
                  type="submit"
                  style={{ backgroundColor: '#D93D6E', color: "white" }}
                >
                  {loading ? "Loading..." : isEdit ? "Update Market" : "Create Market"}
                </button>
                </div>
                 
              </div>

            
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};

export default AddMarket;
