import React, { useEffect, useState } from "react";
import {
  Formik,
  Form,
  Field,
  FieldArray,
  ErrorMessage,
  useFormik,
} from "formik";
import { Select, Creatable } from "react-select";
import { Link, useNavigate, useParams } from "react-router-dom";
import MultiSelectDropdown from "../../components/MultiSelectDropDown2"; //Note using second version
import ImageUploadPreview from "../../components/ImageUploadPreview";
import Layout from "../../components/layouts/Layout";
import QuillEditor from "../../components/Editor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faTrash,
  faUpload,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import { addProductValidation } from "../../validations/addProductValidation";
import { fetchAllCategories } from "../../features/category/categorySlice";
import { useDispatch } from "react-redux";
import { fetchAllBrands } from "../../features/brand/brandSlice";
import { FaArrowLeft } from "react-icons/fa";
import Cookies from "js-cookie";

import {
  addProduct,
  fetchProductById,
  updateProduct,
  deleteProduct,
} from "../../features/product/productSlice";

import DraggableMediaGallery from "../../components/DraggableMediaGallery";
import { useSelector } from "react-redux";
import { getAllMarkets, getSelectedMarket } from "../../features/market/marketSlice";
import ImageUploader from "../../components/imageUploader";
const mediaFolder = process.env.REACT_APP_MEDIA_URL;
const debugMode = process.env.REACT_APP_DEBUG || "";
const EditProductNew = () => {
  const { id } = useParams();
  const isEditMode = id !== undefined;

  const allMarkets = useSelector(getAllMarkets);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState({});

  const [categories, setCategories] = useState([]);
  const [currencySymbol, setCurrencySymbol] = useState('');
  
  const [brands, setBrands] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]); // categories values of product
 
  const [product, setProduct] = useState({});
  const [brand, setBrand] = useState({});

  const [images, setImages] = useState([]);

  const [formData, setFormData] = useState([]);

  const [open, setOpen] = useState(false);

  const [mediaItems, setMediaItems] = useState([]);
  const [marketSlug, setmarketSlug] = useState("");

  const [productTags, setProductTags] = useState([]); // Holds the tags
  const [tagInput, setTagInput] = useState(""); // Holds the current input value

  const [descriptionShortImagePreview, setDescriptionShortImagePreview] =
    useState(""); // State to hold image preview
  const selectedMarket = useSelector(getSelectedMarket);


  useEffect(() => {
    // Find the selected market and set its currency symbol
    const selectedMarketDetails = allMarkets.data?.find(
        (market) => market.slug === selectedMarket
    );
    setCurrencySymbol(selectedMarketDetails?.currency?.symbol || '');
}, [allMarkets, selectedMarket]);


  const handleInputChange = (e) => {
    setTagInput(e.target.value);
    if (e.target.value.includes(",")) {
      // Split tags on comma
      const tags = e.target.value
        .split(",")
        .map((tag) => tag.trim())
        .filter((tag) => tag && !productTags.includes(tag));
      setProductTags([...productTags, ...tags]);
      setTagInput(""); // Reset input field
    }
  };

  const handleAddTag = () => {
    if (tagInput && !productTags.includes(tagInput.trim())) {
      setProductTags([...productTags, tagInput.trim()]);
      setTagInput(""); // Clear the input after adding a tag
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setProductTags(productTags.filter((tag) => tag !== tagToRemove));
  };

  const handleDelete = () => {
    // Use the browser's confirm dialog to confirm deletion
    if (window.confirm("Are you sure you want to delete this product?")) {
      dispatch(deleteProduct({ id: id }))
        .unwrap()
        .then(() => {
          toast.success("Product deleted successfully!");
          navigate("/product"); // Adjust route as needed
        })
        .catch((error) => {
          toast.error(`Failed to delete product: ${error}`);
        });
    }
  };

  const defaultInitialValues = {
    name: "",
    description: "",
    description_short: "",
    description_short_title: "",
    description_short_image: "",
    description_short_image_file_id: "",
    sku: "",
    weight: 0,
    length: 0,
    width: 0,
    quantity_min: 1, // Default to a minimum quantity of 1
    stock: 0,
    price: "",
    media: [],
    media_fileId: [],
    discounted_price: "",
    cost: "",
    published: false,
    is_upsell: false,
    brand_id: "",
    categories: [],
    files: [],
    options: [],
    productVariants: [],
    productTags: [],
  };

  const imageBaseUrl = `${process.env.REACT_APP_MEDIA_URL}`;

  const fetchBrands = async () => {
    const res = await dispatch(fetchAllBrands({ selectedMarket })).unwrap();
    //  console.log(res)
    setBrands(res);
  };

  const fetchCategories = async () => {
    // fetch all categories
    const res = await dispatch(fetchAllCategories({ selectedMarket })).unwrap();
    console.log("res",res)
    
  
    const categoryOptions = res.map((cat) => ({
      value: cat._id,
      label: cat.name,
    }));
    console.log(categoryOptions);
    setCategories(categoryOptions);
  };

  useEffect(() => {
    fetchBrands();
    fetchCategories();
  }, [dispatch]);



  useEffect(() => {
    if (isEditMode) {
      fetchProduct(); // Only fetch product if in edit mode
    } else {
      setInitialValues(defaultInitialValues); // Set default for add new product
    }

    const savedMarket = Cookies.get("selectedMarket");
    setmarketSlug(savedMarket);
  }, [categories, brands, isEditMode]);



  const fetchProduct = async () => {
    try {
      const response = await dispatch(fetchProductById({ id })).unwrap();
      const { product } = response;

      console.log("Fetched Product Data");
      console.log(product);

      const productCategories = product.categories
        ? categories.filter((cat) => product.categories.includes(cat.value))
        : [];
      console.log(productCategories);
      setSelectedCategories(productCategories);

      const formattedVariants =
        product.product_variants?.map((variant) => ({
          name: variant.name || "",
          price: variant.price || "",
          discounted_price: variant.discounted_price || "",
          stock: variant.stock || "",
          sku: variant.sku || "",
          image: variant.image || "",
          _id: variant._id || "", // Assuming each variant has a unique ID
        })) || [];

      const initialValues = {
        name: product.name || "",
        description: product.description || "",
        description_short: product.description_short || "",
        description_short_title: product.description_short_title || "",
        description_short_image: product.description_short_image || "",
        sku: product.sku || "",
        weight: product.weight || "",
        length: product.length || "",
        width: product.width || "",
        quantity_min: product.quantity_min || "",
        stock: product.stock || "",
        price: product.price || "",
        discounted_price: product.discounted_price || "",
        cost: product.cost || "",
        published: product.published || false,
        is_upsell: product.is_upsell || false,
        brand_id: product.brand_id?.id || "",
        categories: productCategories,
        productVariants: formattedVariants,
        additional_descriptions: product.additional_descriptions || [],
        media: product.media || [],
        files: [],
        options: [],
        isSyncedWithIQ: product.isSyncedWithIQ || false,
        productTags: product.productTags || [],
      };

      console.log("Initial Values");
      console.log(initialValues);

      setInitialValues(initialValues);
      setFormData(product);
      setMediaItems(product.media || []);
      setProductTags(product.productTags || []);

      setDescriptionShortImagePreview(
        `${mediaFolder}/${product.description_short_image}` || ""
      );
    } catch (error) {
      //console.error('Failed to fetch product details:', error);
      toast.error("Error fetching product details");
    }

    const syncStatus = product.lastSyncWithIQ
      ? `Synced with IQ on: ${new Date(
          product.lastSyncWithIQ
        ).toLocaleString()}`
      : "Not Synced with IQ";
  };

  const capitalize = (str) => {
    return str[0].toUpperCase() + str.slice(1);
  };

  const handleSubmit = async (values, errors) => {
    const data = {
      ...values,
      market: marketSlug,
      // media: mediaItems,
      productTags: productTags,
      categories: values.categories.map((cat) => cat.value),
    };

    console.log(data);
    // console.log("------------")
    //  console.log(errors)

    try {
      let res;
      if (isEditMode) {
        res = await dispatch(updateProduct({ id, values: data })).unwrap();
        toast.success("Product updated successfully!");
      } else {
        res = await dispatch(addProduct(data)).unwrap();
        toast.success("Product added successfully!");
        //console.log(res);
        navigate("/product");
        //if(res) navigate("/editproduct/"+res.product.id); // Redirect to products page after adding
      }

      if (res.status === 400) {
        toast.error(res.message || "An error occurred");
      }
    } catch (error) {
      console.error("Failed to process product:", error);
      toast.error("Failed to process product");
    }
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <Layout>
      <div className="col-12 stretch-card container-fluid edit-product">
        <div
          style={{
            marginBottom: "30px",
            display: "flex",
            alignItems: "center",
            gap: "20px",
            color: "#D93D6E",
          }}
        >
          <FaArrowLeft size={20} cursor="pointer" onClick={goBack} />
          <h2 className="heading">
            {isEditMode ? "Edit Product" : "Add Product"}
          </h2>
        </div>

        <div
          className="page-wrapper"
          id="main-wrapper"
          data-layout="vertical"
          data-navbarbg="skin6"
          data-sidebartype="full"
          data-sidebar-position="fixed"
          data-header-position="fixed"
        >
          <div>
            <div className="container">
              <div className="row">
                {initialValues ? (
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={addProductValidation}
                    validateOnChange={false}
                    validateOnBlur={true}
                    validateOnSubmit={true}
                    onSubmit={(values, errors) => {
                      handleSubmit(values, errors);
                    }}
                  >
                    {({ values, errors, setFieldValue, validateForm }) => (
                      <>
                        {/* {debugMode && <div><pre>{JSON.stringify(values, null, 2)}</pre></div>}
                        {debugMode && <pre>{JSON.stringify(values.categories)}</pre>}
                        {debugMode && <pre>{JSON.stringify(categories)}</pre>} */}
                        <Form>
                          <div className="row">
                            <div className="col-md-9">
                              <div className="card mb-3">
                                <div className="card-body">
                                  {/* Name */}
                                  <div className="mb-3">
                                    <label
                                      htmlFor="name"
                                      className="form-label form-label_heading"
                                    >
                                      Name{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Field
                                      type="name"
                                      className="form-control"
                                      id="name"
                                      name="name"
                                      aria-describedby="nameHelp"
                                    ></Field>
                                    {errors.name && (
                                      <small className="text-danger">
                                        {errors.name}
                                      </small>
                                    )}
                                  </div>

                                  {/* Description */}
                                  <div className="mb-3 pb-3">
                                    <label
                                      htmlFor="name"
                                      className="form-label  "
                                    >
                                      Description
                                    </label>
                                    <Field
                                      name="description"
                                      component={QuillEditor}
                                    />
                                    {errors.description && (
                                      <small className="text-danger">
                                        {errors.description}
                                      </small>
                                    )}
                                  </div>
                                </div>
                              </div>

                              {/* Short Description Fields */}
                              <div className="card mb-3">
                                <div className="card-body">
                                  <div className="form-label form-label_heading ">
                                    Short Description Block
                                  </div>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="description_short_title"
                                      className="form-label "
                                    >
                                      Title
                                    </label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      id="description_short_title"
                                      name="description_short_title"
                                    />
                                    <ErrorMessage
                                      name="description_short_title"
                                      component="small"
                                      className="text-danger"
                                    />
                                  </div>

                                  {/* Short Description */}
                                  <div className="mb-3">
                                    <label
                                      htmlFor="name"
                                      className="form-label"
                                    >
                                      Description
                                    </label>
                                    <Field
                                      as="textarea"
                                      name="description_short"
                                      className="form-control"
                                      rows="2"
                                      cols="50"
                                      placeholder="Enter short description"
                                    />
                                  </div>

                                  {/* Image Upload for Short Description Image */}

                                  <div className="mb-4">
                                    {/* <label
                                      htmlFor="banner"
                                      className="form-label"
                                    >
                                      Image
                                    </label> */}
                                    <ImageUploader
                                      className="imageuploader bg-primary"
                                      setFieldValue={setFieldValue}
                                      imageType={"description_short_image"}
                                      fileType={
                                        "description_short_image_file_id"
                                      }
                                    />

                                    {/* Show selected image */}
                                    {typeof values.description_short_image ==
                                      "string" && (
                                      <img
                                        src={`${process.env.REACT_APP_MEDIA_URL}${values.description_short_image}`}
                                        alt=""
                                        height="100px"
                                        className="ms-4 rounded"
                                      />
                                    )}
                                    {values.description_short_image instanceof
                                      File && (
                                      <img
                                        src={URL.createObjectURL(
                                          values.description_short_image
                                        )}
                                        alt="Selected Banner"
                                        height="100px"
                                        className="rounded"
                                      />
                                    )}

                                    {errors.description_short_image && (
                                      <small className="text-danger">
                                        {errors.description_short_image}
                                      </small>
                                    )}
                                  </div>

                                  {/* Display image preview */}

                                  {/* {descriptionShortImagePreview && (
                                    <div className="mb-2">
                                      <img
                                        src={descriptionShortImagePreview}
                                        height="150px"
                                        alt="Preview"
                                        className="rounded"
                                      />
                                    </div>
                                  )} */}
                                </div>
                              </div>

                              {/* Additional Description */}
                              <div className="card mb-3">
                                <div className="card-body">
                                  <FieldArray name="additional_descriptions">
                                    {({ push, remove }) => (
                                      <div>
                                        <div>
                                          <label
                                            htmlFor="additional_descriptions"
                                            className="form-label form-label_heading "
                                            style={{ marginRight: "10px" }}
                                          >
                                            Additional Descriptions
                                          </label>
                                        </div>

                                        {values.additional_descriptions?.map(
                                          (keyword, index) => (
                                            <div key={index} className="mb-3">
                                              <div>
                                                {/* <label htmlFor="label">
                                                  Label:
                                                </label> */}
                                                <Field
                                                  name={`additional_descriptions.${index}.label`}
                                                  className="form-control"
                                                />
                                                <ErrorMessage
                                                  name="label"
                                                  component="div"
                                                />
                                              </div>
                                              <div className="my-3">
                                                <label
                                                  htmlFor="name"
                                                  className="form-label"
                                                >
                                                  Text
                                                </label>
                                                <Field
                                                  name={`additional_descriptions.${index}.value`}
                                                  component={QuillEditor}
                                                />
                                                {errors.description && (
                                                  <small className="text-danger">
                                                    {errors.value}
                                                  </small>
                                                )}
                                              </div>
                                              {/* <button
                                                className="btn btn-sm btn-danger mt-5"
                                                onClick={() => remove(index)}
                                              >
                                                <span>
                                                  <FontAwesomeIcon
                                                    icon={faTrash}
                                                  />
                                                </span>{" "}
                                                Remove
                                              </button>
                                               */}

                                              <div className="text-end">
                                                <button
                                                  className="btn btn-sm mt-5 text-danger"
                                                  onClick={() => remove(index)}
                                                >
                                                  <span>
                                                    <FontAwesomeIcon
                                                      icon={faXmark}
                                                    />
                                                  </span>{" "}
                                                  Remove
                                                </button>
                                              </div>
                                              <hr
                                                className="bg-dark"
                                                style={{ height: "2px" }}
                                              />
                                            </div>
                                          )
                                        )}
                                        {/* <button
                                          type="button"
                                          className="btn btn-sm btn-dark mt-2"
                                          onClick={() => push("")}
                                        >
                                          Add descriptions
                                        </button> */}
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-outline-dark mt-2"
                                          onClick={() => push("")}
                                        >
                                          Add descriptions
                                        </button>
                                      </div>
                                    )}
                                  </FieldArray>
                                </div>
                              </div>

                              {/* Media */}
                              <div className="card mb-3">
                                <div className="card-body">
                                  <div className="mb-3">
                                    {/* <label
                                      htmlFor="media"
                                      className="form-label"
                                    >
                                      Media
                                    </label>
                                    <div className="form-group">
                                      <label htmlFor="file-upload">
                                        Upload Files
                                      </label>
                                    </div> */}
                                    {/* <DraggableMediaGallery mediaItems={mediaItems} setMediaItems={setMediaItems} /> */}
                                    <div>
                                      <label
                                        htmlFor="Media Manager "
                                        className="form-label form-label_heading "
                                        style={{ marginRight: "10px" }}
                                      >
                                        Media Manager
                                      </label>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "20px",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      {Array.isArray(values.media) &&
                                        values.media.map((file, index) => (
                                          <div
                                            key={index}
                                            className="col-md-3 mb-2"
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              gap: "10px",
                                            }}
                                          >
                                            {/* Check if the file is an object (uploaded from system) or a string (filename from file manager) */}
                                            {typeof file === "string" ? (
                                              // If it's a filename, display the image using the URL from the environment variable
                                              <img
                                                src={`${process.env.REACT_APP_MEDIA_URL}${file}`}
                                                height="150px"
                                                width="150px"
                                                alt="Selected"
                                                style={{
                                                  objectFit: "contain",
                                                  marginBottom: "",
                                                }}
                                              />
                                            ) : (
                                              // If it's a file object, use URL.createObjectURL to preview the uploaded file
                                              <img
                                                src={URL.createObjectURL(file)}
                                                height="150px"
                                                alt="Selected"
                                              />
                                            )}

                                            {/* <button
                                              type="button"
                                              className="btn btn-sm mt-2"
                                              onClick={() => {
                                                setFieldValue(
                                                  "media",
                                                  values.media.filter(
                                                    (_, i) => i !== index
                                                  )
                                                );
                                              }}
                                              style={{
                                                backgroundColor: "transparent",
                                                border: "1px solid #D93D6E",
                                              }}
                                            >
                                              Remove Image
                                            </button> */}
                                            <button
                                              type="button"
                                              className="btn btn-sm mt-2 text-danger"
                                              onClick={() => {
                                                setFieldValue(
                                                  "media",
                                                  values.media.filter(
                                                    (_, i) => i !== index
                                                  )
                                                );
                                              }}
                                              // style={{
                                              //   backgroundColor: "transparent",
                                              //   border: "1px solid #D93D6E",
                                              // }}
                                            >
                                              <span class="me-1">
                                                <FontAwesomeIcon
                                                  icon={faXmark}
                                                />
                                              </span>
                                              Remove
                                            </button>
                                          </div>
                                        ))}
                                        
                                    </div>
                                    <hr className="bg-dark my-4" />
                                    <ImageUploader
                                      setFieldValue={setFieldValue}
                                      imageType={"media"}
                                      fileType={"media_fileId"}
                                      allowMultiple={true}
                                      values={values}
                                    />
                                  </div>
                                </div>
                              </div>

                              {/* Pricing */}
                              <div className="card mb-3">
  <div className="card-body">
    <div className="row">
      <h6>
        <b>Pricing</b>
      </h6>
    </div>

    <div className="row">
      <div className="col-md-6 mt-3">
        <label htmlFor="price" className="form-label">
          Price <span className="text-danger">*</span>
        </label>
        <div className="input-group">
          {currencySymbol && <span className="input-group-text">{currencySymbol}</span>}
          <Field
            type="number"
            className="form-control"
            id="price"
            name="price"
            aria-describedby="priceHelp"
          />
        </div>
        {errors.price && <small className="text-danger">{errors.price}</small>}
      </div>

      <div className="col-md-6 mt-3">
        <label htmlFor="discounted_price" className="form-label">
          Discounted Price
        </label>
        <div className="input-group">
          {currencySymbol && <span className="input-group-text">{currencySymbol}</span>}
          <Field
            type="number"
            className="form-control"
            id="discounted_price"
            name="discounted_price"
            aria-describedby="discountPriceHelp"
          />
        </div>
        {errors.discounted_price && (
          <small className="text-danger">{errors.discounted_price}</small>
        )}
      </div>
    </div>

    <div className="row mt-3">
      <div className="col-md-6">
        <label htmlFor="cost" className="form-label">
          Cost
        </label>
        <div className="input-group">
          {currencySymbol && <span className="input-group-text">{currencySymbol}</span>}
          <Field
            type="number"
            className="form-control"
            id="cost"
            name="cost"
            aria-describedby="costHelp"
          />
        </div>
        {errors.cost && <small className="text-danger">{errors.cost}</small>}
      </div>
    </div>
  </div>
</div>

                              {/* Inventory & Shipping */}
                              <div className="card mb-3">
                                <div className="card-body">
                                  <h6>
                                    <b>Inventory & Shipping</b>
                                  </h6>
                                  <div className="row mt-4">
                                    <div className="col-md-4">
                                      <label
                                        htmlFor="sku"
                                        className="form-label"
                                      >
                                        SKU{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        id="sku"
                                        name="sku"
                                        aria-describedby="skuHelp"
                                      ></Field>
                                      {errors.sku && (
                                        <small className="text-danger">
                                          {errors.sku}
                                        </small>
                                      )}
                                    </div>
                                    <div className="col-md-4">
                                      <label
                                        htmlFor="quantity_min"
                                        className="form-label"
                                      >
                                        Min quantity per order
                                      </label>
                                      <Field
                                        type="number"
                                        className="form-control"
                                        id="quantity_min"
                                        name="quantity_min"
                                        aria-describedby="quantityMinHelp"
                                      ></Field>
                                      {errors.quantity_min && (
                                        <small className="text-danger">
                                          {errors.quantity_min}
                                        </small>
                                      )}
                                    </div>
                                    <div className="col-md-4">
                                      <label
                                        htmlFor="stock"
                                        className="form-label"
                                      >
                                        Stock
                                      </label>
                                      <Field
                                        type="number"
                                        className="form-control"
                                        id="stock"
                                        name="stock"
                                        aria-describedby="stockHelp"
                                      ></Field>
                                      {errors.stock && (
                                        <small className="text-danger">
                                          {errors.stock}
                                        </small>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row mt-3">
                                    <div className="col-md-3">
                                      <label
                                        htmlFor="length"
                                        className="form-label"
                                      >
                                        Length
                                      </label>
                                      <Field
                                        type="number"
                                        className="form-control"
                                        id="length"
                                        name="length"
                                        aria-describedby="lengthHelp"
                                      ></Field>
                                      {errors.length && (
                                        <small className="text-danger">
                                          {errors.length}
                                        </small>
                                      )}
                                    </div>
                                    <div className="col-md-3">
                                      <label
                                        htmlFor="width"
                                        className="form-label"
                                      >
                                        Width
                                      </label>
                                      <Field
                                        type="number"
                                        className="form-control"
                                        id="width"
                                        name="width"
                                        aria-describedby="widthHelp"
                                      ></Field>
                                      {errors.width && (
                                        <small className="text-danger">
                                          {errors.width}
                                        </small>
                                      )}
                                    </div>
                                    <div className="col-md-3">
                                      <label
                                        htmlFor="height"
                                        className="form-label"
                                      >
                                        Height
                                      </label>
                                      <Field
                                        type="number"
                                        className="form-control"
                                        id="height"
                                        name="height"
                                        aria-describedby="heightHelp"
                                      ></Field>
                                      {errors.height && (
                                        <small className="text-danger">
                                          {errors.height}
                                        </small>
                                      )}
                                    </div>
                                    <div className="col-md-3">
                                      <label
                                        htmlFor="weight"
                                        className="form-label"
                                      >
                                        Weight
                                      </label>
                                      <Field
                                        type="number"
                                        className="form-control"
                                        id="weight"
                                        name="weight"
                                        aria-describedby="weightHelp"
                                      ></Field>
                                      {errors.weight && (
                                        <small className="text-danger">
                                          {errors.weight}
                                        </small>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Tag Input Field */}
                              <div className="card mb-3">
                                <div className="card-body">
                                  <label
                                    htmlFor="tagInput"
                                    className="form-label"
                                  >
                                    Product Tags
                                  </label>
                                  <div className="input-group mb-3">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="tagInput"
                                      placeholder="Enter tags"
                                      value={tagInput}
                                      onChange={handleInputChange}
                                      onKeyDown={(e) =>
                                        e.key === "Enter" && e.preventDefault()
                                      } // Prevent form submission on Enter
                                    />
                                    <button
                                      className="btn btn-outline-primary"
                                      type="button"
                                      onClick={handleAddTag}
                                    >
                                      Add Tag
                                    </button>
                                  </div>
                                  <div className="d-flex flex-wrap">
                                    {productTags.map((tag, index) => (
                                      <div
                                        key={index}
                                        className="badge bg-dark m-1"
                                      >
                                        {tag}{" "}
                                        <span
                                          className="ms-1"
                                          onClick={() => handleRemoveTag(tag)}
                                          style={{ cursor: "pointer" }}
                                        >
                                          ×
                                        </span>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>

                              {/* Variants */}
                              <div className="card mb-3">
                                <div className="card-body">
                                  <h6>
                                    <b>Variants</b>
                                  </h6>

                                  {/* <FieldArray name="productVariants">
                                    {({ push, remove, form }) => (
                                      <div>
                                        <table className="table table-responsive table-bordered">
                                          <thead style={{ fontSize: "12px" }}>
                                            <tr>
                                              <th></th>
                                              <th width="200">Name</th>
                                              <th>Price</th>
                                              <th>Discounted Price</th>
                                              <th>Stock</th>
                                              <th>SKU</th>
                                              <th></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {values.productVariants &&
                                            values.productVariants.length >
                                              0 ? (
                                              values.productVariants.map(
                                                (variant, index) => (
                                                  <tr key={index}>
                                                    <td>
                                                      <ImageUploadPreview
                                                        name={`productVariants[${index}][image]`} // Dynamic name for the input
                                                        imageFile={
                                                          variant.image
                                                        } // This is just the image file name from the server
                                                        setImageFile={(file) =>
                                                          setFieldValue(
                                                            `productVariants.${index}.image`,
                                                            file
                                                          )
                                                        }
                                                        imagePreview={
                                                          variant.imagePreview ||
                                                          (variant.image
                                                            ? `${mediaFolder}/${variant.image}`
                                                            : null)
                                                        }
                                                        setImagePreview={(
                                                          preview
                                                        ) =>
                                                          setFieldValue(
                                                            `productVariants.${index}.imagePreview`,
                                                            preview
                                                          )
                                                        }
                                                      />
                                                    </td>
                                                    <td>
                                                      <Field
                                                        required
                                                        type="text"
                                                        name={`productVariants.${index}.name`}
                                                        className="form-control form-control-sm"
                                                      />
                                                    </td>
                                                    <td>
                                                      <Field
                                                        required
                                                        type="number"
                                                        name={`productVariants.${index}.price`}
                                                        className="form-control form-control-sm"
                                                      />
                                                    </td>
                                                    <td>
                                                      <Field
                                                        type="number"
                                                        name={`productVariants.${index}.discounted_price`}
                                                        className="form-control  form-control-sm"
                                                      />
                                                    </td>
                                                    <td>
                                                      <Field
                                                        required
                                                        type="number"
                                                        name={`productVariants.${index}.stock`}
                                                        className="form-control  form-control-sm"
                                                      />
                                                    </td>
                                                    <td>
                                                      <Field
                                                        type="text"
                                                        name={`productVariants.${index}.sku`}
                                                        className="form-control  form-control-sm"
                                                      />
                                                    </td>
                                                    <td>
                                                      {variant._id && (
                                                        <input
                                                          type="hidden"
                                                          name={`productVariants.${index}._id`}
                                                          value={variant._id}
                                                        />
                                                      )}
                                                      <button
                                                        type="button"
                                                        className="btn btn-link text-danger"
                                                        onClick={() =>
                                                          remove(index)
                                                        }
                                                      >
                                                        X
                                                      </button>
                                                    </td>
                                                  </tr>
                                                )
                                              )
                                            ) : (
                                              <tr>
                                                <td
                                                  colSpan="6"
                                                  className="text-center"
                                                >
                                                  No variants added
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                        <button
                                          type="button"
                                          className="btn btn-outline-primary"
                                          onClick={() =>
                                            push({
                                              name: "",
                                              price: "",
                                              discounted_price: "",
                                              stock: "",
                                              sku: "",
                                            })
                                          }
                                        >
                                          Add Variant
                                        </button>
                                      </div>
                                    )}
                                  </FieldArray> */}

    <FieldArray name="productVariants">
  {({ push, remove, form }) => (
    <div>
      <table className="table table-responsive table-bordered">
        <thead style={{ fontSize: "12px" }}>
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>Price</th>
            <th>Discounted Price</th>
            <th>Stock</th>
            <th>SKU</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {values.productVariants && values.productVariants.length > 0 ? (
            values.productVariants.map((variant, index) => (
              <tr key={index}>
                <td>
                  <ImageUploader
                    setFieldValue={setFieldValue}
                    imageType={`productVariants.${index}.image`}
                    fileType={`productVariants.${index}.fileId`}
                    allowMultiple={false}
                    values={values.productVariants[index]} // Pass the specific variant values
                  />
                  {variant.image && (
                    <img
                      src={`${process.env.REACT_APP_MEDIA_URL}${variant.image}`}
                      alt="Variant"
                      style={{ width: "100px", height: "100px", marginTop: "10px" }}
                    />
                  )}
                </td>
                <td>
                  <Field
                    type="text"
                    name={`productVariants.${index}.name`}
                    className="form-control form-control-sm"
                  />
                </td>
                <td>
                  <Field
                    type="number"
                    name={`productVariants.${index}.price`}
                    className="form-control form-control-sm"
                  />
                </td>
                <td>
                  <Field
                    type="number"
                    name={`productVariants.${index}.discounted_price`}
                    className="form-control form-control-sm"
                  />
                </td>
                <td>
                  <Field
                    type="number"
                    name={`productVariants.${index}.stock`}
                    className="form-control form-control-sm"
                  />
                </td>
                <td>
                  <Field
                    type="text"
                    name={`productVariants.${index}.sku`}
                    className="form-control form-control-sm"
                  />
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-link text-danger"
                    onClick={() => remove(index)}
                  >
                     X
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" className="text-center">
                No variants added
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <button
        type="button"
        className="btn btn-outline-primary"
        onClick={() =>
          push({
            name: "",
            price: "",
            discounted_price: "",
            stock: "",
            sku: "",
            image: "",
            fileId: "",
          })
        }
      >
        Add Variant
      </button>
    </div>
  )}
</FieldArray>



                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="card mb-3">
                                <div className="card-body">
                                  {/* Published */}
                                  <div>
                                    <label
                                      htmlFor="published"
                                      className="form-label"
                                    >
                                      Published:
                                    </label>
                                    <Field
                                      as="select"
                                      id="published"
                                      name="published"  
                                      // placeholder="Select label"
                                      className="form-select"
                                    >
                                       <option value="false">Draft</option>
                                      <option value="true">Published</option>
                                      {/* Add more options as needed */}
                                    </Field>
                                    {errors.published && (
                                      <small className="text-danger">
                                        {errors.published}
                                      </small>
                                    )}
                                  </div>

                                  {/* Upsell */}
                                  <div className="mt-4">
                                    <label
                                      htmlFor="is_upsell"
                                      className="form-label"
                                    >
                                      Upsell (Cart Page):
                                    </label>
                                    <Field
                                      as="select"
                                      id="is_upsell"
                                      name="is_upsell"
                                      // placeholder="Select label"
                                      className="form-select"
                                    >
                                      <option value="false">No</option>
                                      <option value="true">Yes</option>
                                      {/* Add more options as needed */}
                                    </Field>
                                    {errors.is_upsell && (
                                      <small className="text-danger">
                                        {errors.is_upsell}
                                      </small>
                                    )}

                                    {/* <ErrorMessage
                                  name="published"
                                  component="div"
                                /> */}
                                  </div>
                                </div>
                              </div>
                              <div className="card mb-3">
                                <div className="card-body">
                                  {/* Category */}
                                  <div>
                                    <label
                                      htmlFor="category"
                                      className="form-label"
                                    >
                                      Category:
                                    </label>
                                    <MultiSelectDropdown
                                      name="categories"
                                      options={categories}
                                      onChange={(selectedOption) => {
                                        console.log("selectedOption",selectedOption)

                                        // console.log("selectedOption",selectedOption)
                                        setFieldValue(
                                          "categories",
                                          selectedOption
                                        );
                                         setSelectedCategories(
                                          selectedOption.map((cat) => cat.value)
                                        );
                                      }}
                                    />

                                    {/* 
                                  <MultiSelectDropdown
                                      name="categories"
                                      options={categories.map(cat => ({
                                        value: cat.id,  // Assuming you're using 'id' from categories as the value.
                                        label: cat.name // The name field from categories as the label.
                                      }))}
                                      value={values.categories.map((selectedCategory) => {
                                        // Finding each selected category in the 'categories' array by its 'id'.
                                        const category = categories.find((cat) => cat.id === selectedCategory.id);
                                        return category ? { value: category.id, label: category.name } : null;
                                      }).filter(Boolean)} // Filters out any null values if a category wasn't found
                                      onChange={(selectedOption) => {
                                        // Handling change, assuming you want to save the selected options.
                                        setFieldValue("categories", selectedOption);
                                      }}
                                    /> */}

                                    {errors.category && (
                                      <small className="text-danger">
                                        {errors.category}
                                      </small>
                                    )}
                                    <ErrorMessage
                                      name="category"
                                      component="div"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="card mb-3">
                                <div className="card-body">
                                  {/* Brand */}
                                  <div>
                                    <label
                                      htmlFor="brand"
                                      className="form-label"
                                    >
                                      Brand:
                                    </label>
                                    <Field
                                      as="select"
                                      id="brand_id"
                                      name="brand_id"
                                      value={values.brand_id}
                                      className="form-select"
                                    >
                                      <option value="">Select Brand</option>
                                      {brands?.map((brand) => (
                                        <option value={brand.id} key={brand.id}>
                                          {capitalize(brand.name)}
                                        </option>
                                      ))}
                                    </Field>
                                    {errors.brand_id && (
                                      <small className="text-danger">
                                        {errors.brand_id}
                                      </small>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 d-flex justify-content-between">
                              <button
                                type="submit"
                                className="btn btn-primary mt-2"
                              >
                                {isEditMode ? "Update Product" : "Add Product"}
                              </button>

                              {isEditMode ? (
                                <button
                                  type="button"
                                  onClick={handleDelete}
                                  className="btn btn-outline-danger mt-2"
                                >
                                  Delete
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </Form>
                      </>
                    )}
                  </Formik>
                ) : (
                  <div>Loading...</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EditProductNew;
