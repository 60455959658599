import React, { useEffect } from "react";
import Layout from "../../components/layouts/Layout";
import { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { addBrandValidation } from "../../validations/addBrandValidation";
import { FaArrowLeft } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { addBrand } from "../../features/brand/brandSlice";
import { IoMdClose } from "react-icons/io";
import Cookies from "js-cookie";
import ImageUploader from "../../components/imageUploader";
const AddBrand = () => {
  const [loading, setLoading] = useState(false);
  const [error, seError] = useState([]);
  const [marketSlug, setmarketSlug] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    name: "",
    description: "",
    logo: "",
    logoFileId: "",
    website: "",
    images: [],
    imagesFileId: [],
    labels: [],
    slide_show: [],
    slideShowFileId: [],
  };

  const handleSubmit = async (values) => {
    values.market = marketSlug;
    console.log(values);

    const res = await dispatch(addBrand(values)).unwrap();
    if (res) {
      toast.success("Brand created successfully!");
      navigate("/brand");
    }
  };

  useEffect(() => {
    const savedMarket = Cookies.get("selectedMarket");
    setmarketSlug(savedMarket);
  }, []);
  const goBack = () => {
    window.history.back();
  };
  return (
    <Layout>
      <div className="col-12 stretch-card container-fluid">
        <div
          style={{
            marginBottom: "30px",
            display: "flex",
            alignItems: "center",
            gap: "20px",
            color: "#D93D6E",
          }}
        >
          <FaArrowLeft size={20} cursor="pointer" onClick={goBack} />
          <h2 className="heading">Add Brands</h2>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={addBrandValidation}
          onSubmit={(values) => {
            console.log(values);
            handleSubmit(values);
          }}
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              <div className="card">
                <div className="card-body">
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Brand Name
                    </label>
                    <Field
                      type="name"
                      className="form-control"
                      id="name"
                      name="name"
                      aria-describedby="nameHelp"
                    ></Field>
                    {errors.name && (
                      <small className="text-danger">{errors.name}</small>
                    )}
                  </div>
                  <div className="mb-4">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <Field
                      as="textarea"
                      type="text"
                      className="form-control"
                      id="description"
                      name="description"
                    ></Field>
                    {errors.description && (
                      <small className="text-danger">
                        {errors.description}
                      </small>
                    )}
                  </div>
                  <div className="mb-4 " style={{width:"30px"}}>  
                    <label htmlFor="color" className="form-label">
                      Color
                    </label>
                    <Field
                      type="color"
                      className="form-control p-1"
                      id="color"
                      name="color"
                      placeholder="Select a color"
                    ></Field>
                    {errors.color && (
                      <small className="text-danger">{errors.color}</small>
                    )}
                  </div>

                  <div className="mb-4">
                    <label htmlFor="website" className="form-label">
                      Website
                    </label>
                    <Field
                      type="website"
                      className="form-control"
                      id="website"
                      name="website"
                    ></Field>
                    {errors.website && (
                      <small className="text-danger">{errors.website}</small>
                    )}
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-body">
                  <div className="mb-4">
                    <div className="d-flex flex-column">
                      <label htmlFor="banner" className="form-label">
                        Logo
                      </label>
                      <ImageUploader
                        setFieldValue={setFieldValue}
                        imageType={"logo"}
                        fileType={"logoFileId"}
                      />
                    </div>
                    {/* Show selected image */}
                    {typeof values.logo == "string" && (
                      <img
                        src={`${process.env.REACT_APP_MEDIA_URL}${values.logo}`}
                        alt=""
                        height="100px"
                      />
                    )}
                    {values.logo instanceof File && (
                      <img
                        src={URL.createObjectURL(values.logo)}
                        alt="Selected Banner"
                        height="100px"
                      />
                    )}

                    {errors.logo && (
                      <small className="text-danger">{errors.logo}</small>
                    )}
                  </div>

                  <div>
                    <h4>Upload Images</h4>
                    <FieldArray name="images">
                      {({ push, remove }) => (
                        <div>
                          {values.images.map((image, index) => (
                            <div
                              key={index}
                              style={{ display: "flex", gap: "30vh" }}
                            >
                              <div className="mb-1">
                                <ImageUploader
                                  setFieldValue={setFieldValue}
                                  imageType={`images[${index}]`}
                                  fileType={`imagesFileId[${index}]`}
                                  allowMultiple={false}
                                />

{values.images[index] &&typeof values.images[index] === "string"&& (
              <div className="mt-2">
               
                  <img
                    src={`${process.env.REACT_APP_MEDIA_URL}${values.images[index]}`}
                    alt={`Image ${index + 1}`}
                    height="100px"
                  />
            
              </div>
            )}
                              </div>
                              <div style={{ marginBottom: "1rem" }}>
                                <label
                                  htmlFor="label"
                                  style={{
                                    fontWeight: "bold",
                                    marginBottom: "0.5rem",
                                    marginRight: "10px",
                                  }}
                                >
                                  Label:
                                </label>
                                <Field
                                  as="select"
                                  id="label"
                                  name={`labels.${index}`}
                                  placeholder="Select label"
                                  //   className="form-select"
                                  style={{
                                    padding: "0.5rem",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                  }}
                                >
                                  <option value="">Select Label</option>
                                  <option value="cover">
                                    Cover Image (Used as Background)
                                  </option>
                                  {/* Add more options as needed */}
                                </Field>
                                <ErrorMessage name="label" component="div" />
                              </div>
                              <ErrorMessage
                                name={`images[${index}]`}
                                component="div"
                              />

                              <IoMdClose
                                size={32}
                                color="#D93D6E"
                                onClick={() => remove(index)}
                                cursor="pointer"
                              />
                            </div>
                          ))}
                          <button
                            type="button"
                            className="btn btn-sm  mt-2"
                            onClick={() => push({ label: "", file: null })}
                            style={{
                              backgroundColor: "transparent",
                              border: "1px solid #D93D6E",
                            }}
                          >
                            Add Image
                          </button>
                        </div>
                      )}
                    </FieldArray>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-body">
                  <div className="mb-4">
                    <div className="d-flex flex-column">
                      <label htmlFor="slide_show" className="form-label">
                        Slideshow images
                      </label>
                      <ImageUploader
                        setFieldValue={setFieldValue}
                        imageType="slide_show"
                        values={values}
                        fileType={"slideShowFileId"}
                        allowMultiple={true}
                      />
                    </div>

                    <div
                      style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}
                    >
                      {Array.isArray(values.slide_show) &&
                        values.slide_show.map((file, index) => (
                          <div
                            key={index}
                            className="col-md-3 mb-2"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            {/* Check if the file is an object (uploaded from system) or a string (filename from file manager) */}
                            {typeof file === "string" ? (
                              // If it's a filename, display the image using the URL from the environment variable
                              <img
                                src={`${process.env.REACT_APP_MEDIA_URL}${file}`}
                                height="150px"
                                alt="Selected"
                              />
                            ) : (
                              // If it's a file object, use URL.createObjectURL to preview the uploaded file
                              <img
                                src={URL.createObjectURL(file)}
                                height="150px"
                                alt="Selected"
                              />
                            )}

                            <button
                              type="button"
                              className="btn btn-sm mt-2"
                              onClick={() => {
                                setFieldValue(
                                  "slide_show",
                                  values.slide_show.filter(
                                    (_, i) => i !== index
                                  )
                                );
                              }}
                              style={{
                                backgroundColor: "transparent",
                                border: "1px solid #D93D6E",
                              }}
                            >
                              Remove Image
                            </button>
                          </div>
                        ))}
                        
                    </div>
                  </div>
                </div>
              </div>

              <button
                className="btn  w-100 py-8 fs-4 mb-4 rounded-2 mt-4"
                type="submit"
                style={{ backgroundColor: "#D93D6E", color: "white" }}
              >
                {loading ? "Loading..." : "Create Brand"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};

export default AddBrand;
