import React, { useEffect, useState } from 'react';
import Layout from '../../components/layouts/Layout';
import DataTable from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import { Modal, Box, Typography, Button } from '@mui/material';

// import { Modal, Button } from 'react-bootstrap';
import { fetchAllFeedback } from '../../features/feedaback/feedbackSlice';
import { formatDateUAE } from '../../utils/formatDate';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };
const Feedback = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [selectedFeedback, setSelectedFeedback] = useState(null);  // State for the selected feedback message
  const [showModal, setShowModal] = useState(false);  // State for the modal
  const dispatch = useDispatch();

  const columns = [
    {
      name: 'Name',
      selector: row => `${row.firstname} ${row.lastname}`,
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
    },
    {
      name: 'Mobile',
      selector: row => row.mobile,
      sortable: true,
    },
    {
      name: 'Feedback Type',
      selector: row => row.feedback_type,
      sortable: true,
    },
    {
      name: 'Created At',
      selector: row => formatDateUAE(row.createdAt),
      sortable: true,
    },
    {
      name: 'Feedback',
      selector: row => row.message.length > 50 ? `${row.message.substring(0, 50)}...` : row.message,
      sortable: true,
      cell: row => (
        <button
          className="btn btn-link"
          onClick={() => handleFeedbackClick(row.message)}
        >
          {row.message.length > 50 ? `${row.message.substring(0, 50)}...` : row.message}
        </button>
      ),
    },
  ];

  // Fetch feedback data
  const fetchFeedbacks = async () => {
    const res = await dispatch(fetchAllFeedback()).unwrap(); 
    console.log("res",res)
    setFeedbacks(res);
  };

  useEffect(() => {
    fetchFeedbacks();
  }, [dispatch]);

  // Handle click on feedback message to show the modal
  const handleFeedbackClick = (feedback) => {
    setSelectedFeedback(feedback);
    setShowModal(true);
  };

  // Close the modal
  const handleClose = () => {
    setShowModal(false);
    setSelectedFeedback(null);
  };

  return (
    <Layout>
      <h2 className="heading ms-3">Feedback</h2>
      <div className="col-12 stretch-card container-fluid">
        <div className="table-responsive">
          <DataTable
            columns={columns}
            data={feedbacks}
            fixedHeader
            pagination
            highlightOnHover
          />
        </div>

        {/* Modal for displaying full feedback message */}

         <Modal
          open={showModal}
          onClose={handleClose}
          aria-labelledby="feedback-modal-title"
          aria-describedby="feedback-modal-description"
        >
          <Box sx={style}>
            <Typography id="feedback-modal-title" variant="h6" component="h2">
              Feedback Details
            </Typography>
            <Typography id="feedback-modal-description" sx={{ mt: 2 }}>
              {selectedFeedback}
            </Typography>
            <Button onClick={handleClose} sx={{ mt: 2 }} variant="contained" color="primary">
              Close
            </Button>
          </Box>
        </Modal>
      </div>
    </Layout>
  );
};

export default Feedback;
