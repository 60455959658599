import FeedabackService from "../../services/feedback.service";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message/messageSlice";

let initialState = {
    feedbacks: [],
    feedback: {},
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 1,
};




export const fetchAllFeedback = createAsyncThunk(
    "feedback/fetchAllFeedback",
    async (thunkAPI) => {
      try {
        const data = await FeedabackService.getFeedaback();
      //   thunkAPI.dispatch(setMessage(data.message));
      console.log(data)
        return data;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  );





  const FeedbackSlice = createSlice({
    name: 'feedaback',
    initialState,
    reducers: { },
    extraReducers(builder) {
       
        builder.addCase(fetchAllFeedback.fulfilled, (state, action) => {
            //  console.log(action.payload)
         state.feedbacks =  action.payload;
        
        //  state.limit = action.payload.limit;
        //  state.page = action.payload.page;
        //  state.totalPages = action.payload.totalPages;
        //  state.totalResults = action.payload.totalResults

        })

       

   
      
      
    }
})

export default FeedbackSlice.reducer

