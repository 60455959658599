import axiosInstance from "../utils/axiosInstance";

import Cookies from 'js-cookie';


const API_URL = `${process.env.REACT_APP_API_URL}/api/admin/return`;

const savedMarket = Cookies.get('selectedMarket');
const getReturns = async (selectedMarket) => {
    const res = await axiosInstance.get(API_URL + "/",{
        params: {
            market:selectedMarket||savedMarket
        }})

    return res.data;
}

const getReturnsById = async (id) => {
    const res = await axiosInstance.get(API_URL + `/${id}`)

    return res.data;
}


    const createReturn = async (data) => {
        const res = await axiosInstance.post(API_URL + "/",data)
    
        return res.data;
    }




    const updateReturn = async (id,data) => {
        const res = await axiosInstance.post(API_URL + `/${id}/status`,data)
    
        return res.data;
    }


const returnService={

    createReturn,getReturns,getReturnsById,updateReturn


}


export default returnService;



