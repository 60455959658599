// components/ConfirmDeleteModal.js
import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #D93D6E',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const ConfirmDeleteModal = ({ open, onClose, onConfirm, message }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...modalStyle, width: 400 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Confirm Delete
        </Typography>
        <Typography sx={{ mb: 4 }}>{message}</Typography>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Button onClick={onClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={onConfirm} color="secondary" variant="contained">
            Confirm Delete
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmDeleteModal;
