import { Formik, Form, Field } from 'formik';
import { forgotPasswordValidation } from '../validations/forgotPasswordValidation';
import MainLogo from "../assets/images/logos/sthani_logo.png";
import AuthService from '../services/auth.service';
import { toast } from "react-toastify";

    const ForgotPassword = () => {
    const handleForgotPassword = async (values) => {
        try {
            const response = await AuthService.forgotPassword(values.email);
            console.log(response);
            toast.success('Password reset link sent successfully! Please check your email.', {
                position: 'top-right',
                duration: 5000,
            });
        } catch (err) {
            console.error(err);
            toast.error('Failed to send password reset link. Please try again.', {
                position: 'top-right',
                duration: 5000,
            });
        }
    };

    return (
        <div className="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed">
            <div className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
                <div className="d-flex align-items-center justify-content-center w-100">
                    <div className="row justify-content-center w-100">
                        <div className="col-md-8 col-lg-6 col-xxl-3">
                            <div className="card mb-0">
                                <div className="card-body">
                                    <a href="/" className="text-nowrap logo-img text-center d-block py-3 w-100">
                                        <img src={MainLogo} width="120px" alt="Main Logo" />
                                    </a>
                                    <p className="text-center">Forgot Password</p>
                                    <Formik
                                        initialValues={{ email: '' }}
                                        validationSchema={forgotPasswordValidation}
                                        onSubmit={(values) => handleForgotPassword(values)}
                                    >
                                        {({ errors }) => (
                                            <Form>
                                                <div className="mb-3">
                                                    <label htmlFor="email" className="form-label">Email</label>
                                                    <Field
                                                        type="email"
                                                        className="form-control"
                                                        id="email"
                                                        name="email"
                                                        placeholder="Enter your email"
                                                    />
                                                    {errors.email && <small className="text-danger">{errors.email}</small>}
                                                </div>
                                                <button
                                                    className="btn btn-primary w-100 py-8 fs-4 mb-4 rounded-2"
                                                    type="submit"
                                                >
                                                    Send Reset Link
                                                </button>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
