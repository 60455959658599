import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrderById, updateOrderStatus, deleteOrder, createIQOrder } from '../../features/order/orderSlice';
import { useParams, useNavigate } from 'react-router-dom';
import Layout from '../../components/layouts/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { formatDateToDubaiTime } from '../../utils/dateHelper';
import { formatDateUAE } from '../../utils/formatDate';
import { orderStatusFormat, paymentStatusFormat } from '../../utils/statusFormat';
import { Box, Button, Modal, TextField } from '@mui/material';
import returnService from '../../services/return.service';
import { getSelectedMarket } from '../../features/market/marketSlice';

const mediaFolder = process.env.REACT_APP_MEDIA_URL;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const OrderDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const order = useSelector((state) => state.orders.currentOrder);

  const loading = useSelector((state) => state.orders.loading);
  const error = useSelector((state) => state.orders.error);
  const debugMode = process.env.REACT_APP_DEBUG || "";
  const { creatingIQOrder, IQOrderCreated } = useSelector(state => state.orders);

  const [showReturnModal, setShowReturnModal] = useState(false); // Manage modal state
  const [selectedItem, setSelectedItem] = useState(null); // Track selected item for return
  const [returnQuantity, setReturnQuantity] = useState(1); // Track return quantity
  const [returnReason, setReturnReason] = useState('');


  useEffect(() => {

    dispatch(fetchOrderById(id));
  }, [dispatch, id]);

  const selectedMarket = useSelector(getSelectedMarket);

  const handleStatusUpdate = (status) => {
    dispatch(updateOrderStatus({ id, status }));
  };

  const handleDelete = () => {
    if (order) {
      const confirmed = window.confirm('Are you sure you want to delete this order?');
      if (confirmed) {
        dispatch(deleteOrder(order.Order_id))
          .then(() => {
            navigate('/orders'); // Redirect to orders list after deletion
          })
          .catch((error) => {
            console.error('Failed to delete order:', error);
          });
      }
    }
  };

  const handleCreateIQOrder = () => {
    dispatch(createIQOrder(id));
  };


  const handleReturnClick = (item) => {
    setSelectedItem(item);
    setReturnQuantity(item.quantity); // Set default return quantity to current item quantity
    setShowReturnModal(true); // Show the modal
  };

  const handleReturnReasonChange = (e) => {
    setReturnReason(e.target.value);
  };

  const handleConfirmReturn = async () => {
    const customer_full_address = `${order.address.address_line},${order.address.city},${order.address.state}`

    const item = {
      _id: selectedItem._id,
      name: selectedItem.name,
      price: selectedItem.price,
      productId: selectedItem.productId,
      quantity: returnQuantity,
      sku: selectedItem.sku
    }

    const data = {
      customer_first_name: order.customer.name,
      customer_last_name: order.customer.lastname || "",
      customer_full_address,
      order_id: id,
      item: item,
      return_qty: returnQuantity,
      reason: returnReason,
      market:selectedMarket
    }
    // console.log(data)
    await returnService.createReturn(data)
    setShowReturnModal(false);
  };



  const handleReturnQuantityChange = (e) => {
    setReturnQuantity(e.target.value);
  };
  const handleClose = () => setShowReturnModal(false);

  return (
    <Layout>
      
      <div className="col-12 stretch-card container-fluid">

        {loading && <p>Loading...</p>}
        {error && <div class="alert alert-danger" role="alert">

          {error}</div>}
        {order && (
          <>
            <button className="btn btn-link mb-3" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faArrowLeft} className='me-1' /> Back
            </button>
            <div className='row align-center'>
              <div className='col-6'>
                <div className="d-flex align-items-center ">
                  <h2 className='heading ms-3'> Order #{order.order_no}</h2>
                  <div className='ms-4'> {orderStatusFormat(order.orderStatus)} <span className='ms-4'><label className='fw-bolder'>Payment: </label> {paymentStatusFormat(order.paymentStatus)}</span></div>
                </div>
                <div className='d-flex gap-3 justify-between align-center ms-3 pb-5'>

                  <div className=''>Placed on {formatDateUAE(order.createdAt)} </div>

                </div>
              </div>

              <div className='col-6'>
                <div className="d-flex justify-content-end mt-4">

                  {/* Conditional loading text */}
                  {creatingIQOrder && <p>Loading...</p>}

                  {/* Show button only if order status and payment status meet the conditions and order hasn't been sent to IQ yet */}
                  {order && order.orderStatus === 'Order Placed' && order.paymentStatus === "Paid" && !IQOrderCreated && (
                    <button className="btn btn-success me-2" onClick={handleCreateIQOrder} disabled={creatingIQOrder}>
                      Create Order in IQ
                    </button>
                  )}

                  {/* Show success message when order is created in IQ */}
                  {IQOrderCreated && <p>Order created in IQ Fulfillment successfully!</p>}


                  {/* {order.orderStatus === 'Fulfilled' && (
                    <button className="btn btn-success me-2" onClick={() => handleStatusUpdate('Shipped')}>
                      Mark as Shipped
                    </button>
                  )}
                  {order.orderStatus === 'Shipped' && (
                    <button className="btn btn-info me-2" onClick={() => handleStatusUpdate('Delivered')}>
                      Mark as Delivered
                    </button>
                  )}
                  {order.orderStatus !== 'Cancelled' && (
                    <button className="btn btn-dark" onClick={() => handleStatusUpdate('Cancelled')}>
                      Cancel Order
                    </button>
                  )} */}
                </div>
              </div>
            </div>
            <div className="col-12 stretch-card container-fluid">
              <div className="row">
                <div className="col-md-8">
                  <div className='card' >

                    <div className='card-body'>

                      <h5 className='fw-bolder mb-3'>Items</h5>
                      <table className='table'>
                        <tbody>

                          {order.items.map((item, index) => (
                            <tr key={index}>
                              <td width="50"><img src={`${item.image}`} style={{ width: '100%' }} /></td>
                              <td>{item.name} - {item.variant.name}</td>
                              <td>{item.quantity} x AED{item.price}</td>
                              <td>AED {item.total}</td>
                              <td><button className="btn py-0 btn-link text-danger text-uppercase" onClick={() => handleReturnClick(item)}>
                                Return
                              </button></td>


                            </tr>
                          ))}

                        </tbody>
                      </table>



                    </div>

                  </div>

                  <div className='card' >
                    <div className="card-body">
                      <h5 className='fw-bolder mb-3'>Totals</h5>
                      <div className="col-10">
                        <table className='table table-bordered'>
                          <tbody>
                            <tr>
                              <td className='text-dark '>Subtotal:</td>
                              <td>AED {order.subtotal}</td>
                            </tr>
                            <tr>
                              <td className='text-dark'>Shipping:</td>
                              <td>AED {order.shippingAmount?.toFixed(2) || 0}</td>
                            </tr>
                            <tr>
                              <td className='text-dark'>Discount: (Code: {order.discountCode})</td>
                              <td>AED -{order.discountAmount}</td>
                            </tr>
                            <tr>
                              <td className='text-dark'>Total:</td>
                              <td>AED {order.total}</td>
                            </tr>

                          </tbody>

                        </table>
                      </div>

                      {/* <ul className="list-group list-group-flush mt-4">
                        <li className="list-group-item text-end">
                          <strong>Subtotal:</strong> <span style={{ width: "120px", display: "inline-block" }}>AED {order.subtotal}</span>
                        </li>
                        <li className="list-group-item text-end">
                          <strong>Shipping:</strong> <span style={{ width: "120px", display: "inline-block" }}>AED {order.shippingAmount?.toFixed(2) || 0}</span>
                        </li>

                        {order.discountAmount > 0 &&
                          <li className="list-group-item text-end">
                            <strong>Discount: (Code: {order.discountCode})</strong> <span style={{ width: "120px", display: "inline-block" }}>AED -{order.discountAmount}</span>
                          </li>
                        }
                        <li className="list-group-item text-end">
                          <strong>Total:</strong> <span style={{ width: "120px", display: "inline-block" }}>AED {order.total}</span>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">

                  <div className='card' style={{ maxWidth: "400px", border: "1px solid #efefef" }}>
                    <div className='card-body'>
                      <h6 className='fw-bolder mb-2'>Customer Details:</h6>
                      <div className="mb-3 ">
                        {order.customer?.name} <br />
                        {order.customer?.email}<br />
                        {order.customer?.mobile ? order.customer.mobile : "No Mobile number"}
                      </div>
                      <hr />

                      <h6 className='fw-bolder mb-2'>Shipping Address:</h6>
                      <div className="mb-3 ">
                        {order.address.name} <br />
                        {order.address.address_line}, {order.address.city}, {order.address.state}, {order.address.postal_code} <br />
                        {order.address.landmark}
                      </div>

                    </div>
                  </div>




                  <div className='card' >
                    <div className='card-body'>
                      <h5 className='fw-bolder mb-2'>Shipment Details:</h5>
                      {order.shipmentDetails?.trackingNumber ? (
                        <>
                          <strong>Tracking Number:</strong> {order.shipmentDetails.trackingNumber} <br />
                          <strong>Shipping Company:</strong> {order.shipmentDetails.shippingCompany}
                        </>
                      ) : (
                        <em>No shipment details available</em>
                      )}
                    </div>
                  </div>
                </div>
                <div className='col-md-8'>
                  {order && order.statusLogs && (
                    <div className='card' >
                      <div className='card-body'>
                        <h5 className='fw-bolder mb-2'>Order Logs:</h5>
                        <table className='table'>
                          <tbody>
                            {order && order.statusLogs && order.statusLogs.map((log, index) => (
                              <tr key={index}>
                                <th>{log.timestamp}:</th>
                                <th className='text-info-emphasis'>{log.status}</th>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>)}
                    <button className='btn btn-outline-danger  mb-4' onClick={handleDelete}>
                Delete Order
              </button>
              <Modal
                open={showReturnModal}
                onClose={handleClose}
                aria-labelledby="return-item-modal-title"
                aria-describedby="return-item-modal-description"
              >
                <Box sx={style}>
                  <h2 id="return-item-modal-title">Return Item</h2>
                  <p id="return-item-modal-description">
                    You are returning {selectedItem?.name}. Please specify the quantity you wish to return.
                  </p>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="returnQuantity"
                    label="Return Quantity"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={returnQuantity}
                    onChange={handleReturnQuantityChange}
                    inputProps={{ min: 1, max: selectedItem?.quantity }}
                  />

                  <TextField
                    margin="dense"
                    id="returnReason"
                    label="Reason for Return"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={returnReason}
                    onChange={handleReturnReasonChange}
                  />
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleConfirmReturn} color="primary">
                    Confirm Return
                  </Button>
                </Box>
              </Modal>
                </div>
              </div>

            </div>


            <div>



            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default OrderDetails;
