import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import returnService from '../../services/return.service';
import { setMessage } from '../message/messageSlice';


export const fetchAllReturns  = createAsyncThunk(
    'returns/fetchAll',
    async ({selectedMarket},thunkAPI) => {
      
      try {
        const data = await returnService.getReturns(selectedMarket);
        //   thunkAPI.dispatch(setMessage(data.message));
        return data;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  );





  export const fetchReturnsById  = createAsyncThunk(
    'returns/fetchByid',
    async (id,thunkAPI) => {
      console.log(id)
      try {
        const data = await returnService.getReturnsById(id);
        //   thunkAPI.dispatch(setMessage(data.message));
        return data;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  );



  export const UpdateReturnStatus  = createAsyncThunk(
    'returns/updateStatus',
    async ({id,data},thunkAPI) => {

      try {
        const datas = await returnService.updateReturn(id,data);
        //   thunkAPI.dispatch(setMessage(data.message));
        return datas;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  );


const returnsSlice = createSlice({
    name: 'returns',
    initialState: {
      Returndata: [],
      loading: false,
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchAllReturns.pending, (state) => {
          state.loading = true;
        })
        .addCase(fetchAllReturns.fulfilled, (state, action) => {
          state.loading = false;
          state.Returndata = action.payload;
        })
        .addCase(fetchAllReturns.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        })

        .addCase(fetchReturnsById.fulfilled, (state, action) => {
          state.loading = false;
          state.Returndata = action.payload;
          
        })
        .addCase(UpdateReturnStatus.fulfilled, (state, action) => {
          state.loading = false;
          state.Returndata = action.payload;
          
        })
    },
  });
  
  export default returnsSlice.reducer;