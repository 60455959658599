import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  fetchAllMarket,
  setSelectMarket,
} from "../../features/market/marketSlice";
import Aside from "./Aside";
import { ToastContainer } from "react-toastify";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../features/auth/authSlice";

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const [markets, setMarkets] = useState([]);
  const navigate = useNavigate();
  const [selectedMarket, setSelectedMarket] = useState("");
  const [selectedMarketData, setSelectedMarketData] = useState(null);
  const status = 1;

  const { user: currentUser } = useSelector((state) => state.auth) || {};
  const isLoading = useSelector((state) => state.loading.isLoading);

  const handleLogout = async (event) => {
    event.preventDefault();
    const refreshToken = currentUser?.tokens?.refresh?.token;
    dispatch(logout(refreshToken)).then((result) => {
      if (result) {
        navigate("/login");
      }
    });
  };

  const handleChange_profile = async () => {
    navigate("/profile_page");
  };

  // Fetch markets from the API
  const fetchMarket = async () => {
    const res = await dispatch(fetchAllMarket({ status })).unwrap();
    setMarkets(res.data);
  };

  useEffect(() => {
    fetchMarket();
  }, [dispatch]);

  useEffect(() => {
    if (markets.length > 0) {
      const savedMarket = Cookies.get("selectedMarket");
      const defaultMarket = savedMarket || markets[0].slug; // Use the first market if no cookie is set
      dispatch(setSelectMarket(defaultMarket));
      setSelectedMarket(defaultMarket);

      const marketData = markets.find(
        (market) => market.slug === defaultMarket
      );
      setSelectedMarketData(marketData);
    }
  }, [markets]);

  const handleMarketChange = (e) => {
    const newMarketSlug = e.target.value;
    console.log(newMarketSlug);
    dispatch(setSelectMarket(newMarketSlug));
    setSelectedMarket(newMarketSlug);
    Cookies.set("selectedMarket", newMarketSlug, { expires: 7 });

    const marketData = markets.find((market) => market.slug === newMarketSlug);
    setSelectedMarketData(marketData);
  };

  return (
    <div
      className="page-wrapper bg-white"
      id="main-wrapper"
      data-layout="vertical"
      data-navbarbg="skin6"
      data-sidebartype="full"
      data-sidebar-position="fixed"
      data-header-position="fixed"
    >
      <Aside />

      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: "10px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#fffbe5",
            border: "1px solid #f7e7a0",
            borderRadius: "4px",
            padding: "5px 10px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            zIndex: 9999,
            fontFamily: "Arial, sans-serif",
            fontSize: "14px",
            color: "#333",
          }}
        >
          Loading...
        </div>
      )}

      <div className="body-wrapper main-layout ">
        {/* Top-right market selection and image */}
        <div
          className="top-bar d-flex justify-content-end align-items-center "
          style={{ padding: "20px" }}
        >
          {selectedMarketData && (
            <div className="market-info d-flex align-items-center gap-3">
              {/* Logout */}
              <Link
                id="logout"
                className="sidebar-link d-flex align-items-center gap-1"
                onClick={handleLogout}
                to="#"
                aria-expanded="false"
                style={{ textDecoration: "none", color: "#d9534f" }}
              >
                <FontAwesomeIcon icon={faRightFromBracket} />
                <span>Logout</span>
              </Link>

              {/* Flag and Market Name */}
              {selectedMarketData && (
                <>
                  <img
                    src={`${process.env.REACT_APP_MEDIA_URL}${selectedMarketData.flag}`}
                    alt={selectedMarketData.name}
                    width="24"
                    height="16"
                    className="me-2"
                    style={{ borderRadius: "4px", objectFit: "cover" }}
                  />
                  <p className="text-dark me-3 mb-0">
                    {selectedMarketData.name}
                  </p>
                </>
              )}

              {/* Country Selector */}
              <select
                id="market-select"
                className="form-select"
                onChange={handleMarketChange}
                style={{ maxWidth: "200px" }}
                defaultValue="default"
              >
                <option value="default" disabled>
                  Switch Country
                </option>
                {markets.map((market) => (
                  <option key={market.id} value={market.slug}>
                    {market.name.charAt(0).toUpperCase() + market.name.slice(1)}
                  </option>
                ))}
              </select>

              {/* Profile */}
              <Link
                id="profile"
                className="sidebar-link d-flex align-items-center gap-1"
                to="/profile_page"
                aria-expanded="false"
                style={{ textDecoration: "none", color: "#0275d8" }}
              >
                <FontAwesomeIcon icon="fa-solid fa-user" />
                <span onClick={handleChange_profile}>Profile</span>
              </Link>
            </div>
          )}
        </div>

        {/* Main Content Section */}
        <div className="main-content py-3 px-4" style={{ minHeight: "100vh" }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
