import axiosInstance from "../utils/axiosInstance";




const API_URL = `${process.env.REACT_APP_API_URL}/api/admin/media`;






const getmedia = async (data) => {
    const res = await axiosInstance.post(API_URL + "/getmedia",data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },})

    return res.data;
}



const  deletemedia = async (data) => {
    const res = await axiosInstance.post(API_URL +"/deleteMedia",data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },})

    return res.data;
}

const uploadMedia = async (data) => {
    try {
        // console.log(data);
        const res = await axiosInstance.post(API_URL + "/", data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return res.data;
    } catch (err) {
        console.log("ERROR ------->", err);
        // Check for specific MongoDB error codes and provide a more readable message
        // if (err.response && err.response.data && err.response.data.code === "E11000") {
        //     throw new Error(`Category name already exists.`);
        // }
        throw err;
    }
}



const MediaService={
    getmedia,uploadMedia,
    deletemedia
}


export default MediaService;





