import axiosInstance from "../utils/axiosInstance";

const API_URL = `${process.env.REACT_APP_API_URL}/api/admin/profile`;




export const changePassword = async (userId, newPassword) => {
    const response = await axiosInstance.post(`${API_URL}/change_password`, {
      id: userId,
      newPassword,
    });
    return response.data;
  };


  export const uploadProfilePicture = async (userId, file) => {
    if (!userId || !file) {
      throw new Error("User ID and file are required.");
    }
  
    const formData = new FormData();
    formData.append("id", userId); 
    formData.append("profile_pic", file);
  
    try {
      const response = await axiosInstance.post(`${API_URL}/update_profile_pic`, formData);
      return response.data;
    } catch (error) {
      console.error("Error uploading profile picture:", error);
      throw error;
    }
  };



  export const getProfilePicture = async () => {
    try {
      const response = await axiosInstance.get(`${API_URL}/get_profile_pic`);
      return response.data.profile_pic; 
    } catch (error) {
      console.error("Error fetching profile picture:", error);
      throw error;
    }
  };