import React, { useEffect, useState } from "react";
import Layout from "../../components/layouts/Layout";
import DataTable from "react-data-table-component";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {
  Button,
  Typography,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Popover,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { fetchAllMedia } from "../../features/media/mediaSlice";
import MediaService from "../../services/media.service";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  // border: "2px solid #D93D6E",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

const FileManager = () => {
  const [files, setFiles] = useState([]);
  console.log("files", files);
  const [previewFile, setPreviewFile] = useState(null);
  const [selectedFileIds, setSelectedFileIds] = useState([]);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [fileType, setFileType] = useState("");
  const [usedIn, setUsedIn] = useState("");
  const [minSize, setMinSize] = useState(""); // Min size filter in MB
  const [maxSize, setMaxSize] = useState(""); // Max size filter in MB
  const [anchorEl, setAnchorEl] = useState(null); // For the File Size popover
  const dispatch = useDispatch();

  // Fetch media files from API and set to files state
  const fetchFiles = async () => {
    const data = {
      searchTerm: searchTerm,
      fileType: fileType,
      usedIn: usedIn,
      minSize: minSize,
      maxSize: maxSize,
    };
    try {
      const res = await dispatch(fetchAllMedia({ data })).unwrap();
      console.log(res);
      setFiles(res.files); // Set fetched files directly from the response
    } catch (error) {
      console.error("Error fetching media:", error);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, [dispatch, searchTerm, fileType, usedIn, minSize, maxSize]);

  const handlePreview = (file) => {
    // setPreviewFile(file);
    // setShowPreviewModal(true);

    const imageUrl = `${process.env.REACT_APP_MEDIA_URL}${file.diskname}`;
    window.open(imageUrl, "_blank");
  };

  const handleFileChange = (e) => {
    const selected = Array.from(e.target.files);
    setErrorMessage("");

    const allowedExtensions = ["jpeg", "png", "gif", "jpg", "webp"];
    const maxSize = 5 * 1024 * 1024;

    const validFiles = selected.filter((file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        setErrorMessage("Invalid file type. Please select image files only.");
        return false;
      }
      if (file.size > maxSize) {
        setErrorMessage(`File size exceeds ${maxSize / 1024 / 1024} MB.`);
        return false;
      }
      return true;
    });

    setSelectedFiles(validFiles);
  };

  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      setErrorMessage("Please select files to upload!");
      return;
    }

    const uploadPromises = selectedFiles.map(async (file) => {
      const formData = new FormData();
      formData.append("media", file);

      try {
        await MediaService.uploadMedia(formData);
      } catch (error) {
        console.error("Error uploading file:", error);
        setErrorMessage("Error uploading file. Please try again.");
      }
    });

    await Promise.all(uploadPromises);
    fetchFiles();
    setShowUploadModal(false);
    setSelectedFiles([]);
  };

  const handleSelectFile = (fileId) => {
    setSelectedFileIds((prevSelected) =>
      prevSelected.includes(fileId)
        ? prevSelected.filter((id) => id !== fileId)
        : [...prevSelected, fileId]
    );
  };

  const handleDeleteSelected = () => {
    setShowConfirmDelete(true);
  };

  const confirmDelete = async () => {
    const data = {
      fileIds: selectedFileIds,
    };

    try {
      await MediaService.deletemedia(data);
      fetchFiles();
      setSelectedFileIds([]);
      setShowConfirmDelete(false);
    } catch (error) {
      console.error("Error deleting files:", error);
    }
  };

  // Define columns for DataTable
  const columns = [
    {
      name: "",
      cell: (row) => (
        <input
          type="checkbox"
          checked={selectedFileIds.includes(row._id)}
          onChange={() => handleSelectFile(row._id)}
        />
      ),
      width: "5%",
    },
    {
      name: "Thumbnail",
      cell: (row) => (
        <img
          src={`${process.env.REACT_APP_MEDIA_URL}${row.diskname}`}
          alt={row.file_name}
          style={{
            width: "50px",
            height: "50px",
            objectFit: "cover",
            cursor: "pointer",
          }}
          onClick={() => handlePreview(row)}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      width: "10%",
    },
    {
      name: "File Name",
      selector: (row) => row.file_name,
      sortable: true,
      width: "25%",
    },
    {
      name: "Type",
      selector: (row) => row.file_name.split(".").pop().toUpperCase(),
      sortable: true,
      width: "10%",
    },
    {
      name: "Size",
      selector: (row) => row.size,
      sortable: true,
      width: "10%",
    },
    {
      name: "Date Added",
      selector: (row) => new Date(row.createdAt).toLocaleDateString(),
      sortable: true,
      width: "10%",
    },
    {
      name: "References",
      width: "20%",
      cell: (row) => {
        if (row.associations.length === 0) {
          return "-";
        } else {
          return (
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              className="form_dropdown "
            >
              <Select
                defaultValue=""
                displayEmpty
                renderValue={() =>
                  `${row.associations.length} ${
                    row.associations.length === 1 ? "reference" : "references"
                  }`
                }
              >
                {/* Display the count as the default view when the dropdown is closed */}
                <MenuItem disabled value="">
                  {row.associations.length}{" "}
                  {row.associations.length === 1 ? "reference" : "references"}
                </MenuItem>
                {/* List each association with item type and name */}
                {row.associations.map((assoc, index) => (
                  <MenuItem key={index} value={assoc.item_id}>
                    {assoc.item_type} - {assoc.item_name} {`(${assoc.field})`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        }
      },
      sortable: false,
    },
    {
      name: "Link",
      cell: (row) => (
        <CopyToClipboard
          text={`${process.env.REACT_APP_MEDIA_URL}${row.diskname}`}
        >
          {/* <Button variant="outlined" size="small">
            Copy URLs
          </Button> */}
          <FontAwesomeIcon icon={faLink} />
        </CopyToClipboard>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      width: "10%",
    },
  ];

  // Handle the File Size popover
  const handleSizeClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSizeClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "size-popover" : undefined;

  return (
    <Layout>
      <div className="col-12 stretch-card container-fluid media">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="heading ms-3">Media</h2>
          {/* <Button
            variant="contained"
            onClick={() => setShowUploadModal(true)}
            style={{ backgroundColor: "#D93D6E", color: "white" }}
          >
            Upload Files
          </Button> */}
        </div>

        {/* Search and Filter Section */}
        <div className="d-flex mt-3 mb-3">
          <TextField
            label="Search files"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ marginRight: "10px", width: "250px" }}
            // className='custom-input '
          />
          <FormControl
            variant="outlined"
            style={{ marginRight: "10px", minWidth: "150px" }}
          >
            <InputLabel>File type</InputLabel>
            <Select
              value={fileType}
              onChange={(e) => setFileType(e.target.value)}
              label="File type"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="JPG">JPG</MenuItem>
              <MenuItem value="PNG">PNG</MenuItem>
              <MenuItem value="GIF">GIF</MenuItem>
              <MenuItem value="WEBP">WEBP</MenuItem>
            </Select>
          </FormControl>

          {/* File Size Dropdown */}
          <FormControl
            variant="outlined"
            style={{ minWidth: "150px", marginRight: "10px" }}
          >
            <InputLabel>File size</InputLabel>
            <Select
              label="File size"
              onClick={handleSizeClick}
              value="" // Keep value empty to use as a trigger
            >
              <MenuItem value="">Select File Size</MenuItem>
            </Select>
          </FormControl>

          {/* Popover for Min and Max size */}
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleSizeClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Box p={2} display="flex" flexDirection="column">
              <TextField
                label="Min size (MB)"
                variant="outlined"
                type="number"
                value={minSize}
                onChange={(e) => setMinSize(e.target.value)}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                label="Max size (MB)"
                variant="outlined"
                type="number"
                value={maxSize}
                onChange={(e) => setMaxSize(e.target.value)}
              />
            </Box>
          </Popover>

          <FormControl variant="outlined" style={{ minWidth: "150px" }}>
            <InputLabel>Used in</InputLabel>
            <Select
              value={usedIn}
              onChange={(e) => setUsedIn(e.target.value)}
              label="Used in"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="Product">Product</MenuItem>
              <MenuItem value="Brand">Brand</MenuItem>
              <MenuItem value="Category">Category</MenuItem>
              <MenuItem value="Home">Home</MenuItem>
            </Select>
          </FormControl>

          <Button
            variant="contained"
            onClick={() => setShowUploadModal(true)}
            style={{ backgroundColor: "#D93D6E", color: "white" }}
            className="ms-2"
          >
            Upload Files
          </Button>
        </div>
        {/* Delete button that appears when files are selected */}
        {selectedFileIds.length > 0 && (
          <div className="d-flex justify-content-end mt-3">
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDeleteSelected}
            >
              Delete Selected ({selectedFileIds.length})
            </Button>
          </div>
        )}
        {/* Data Table to Display Files */}
        <DataTable
          columns={columns}
          data={files}
          pagination
          highlightOnHover
          fixedHeader
          noDataComponent="No records to display"
        />
      </div>

      {/* Confirmation Modal for Deletion */}
      <ConfirmDeleteModal
        open={showConfirmDelete}
        onClose={() => setShowConfirmDelete(false)}
        onConfirm={confirmDelete}
        message="Are you sure you want to delete the selected files? This action cannot be undone."
      />

      {/* Modal for image preview */}
      <Modal open={showPreviewModal} onClose={() => setShowPreviewModal(false)}>
        <Box sx={{ ...style, width: 600 }}>
          <Typography variant="h6">Image Preview</Typography>
          <Box sx={{ mt: 2 }}>
            {previewFile && (
              <img
                src={`${process.env.REACT_APP_MEDIA_URL}${previewFile.diskname}`}
                alt="Preview"
                style={{ width: "100%" }}
              />
            )}
          </Box>
          <Box sx={{ mt: 3 }}>
            <Button
              onClick={() => setShowPreviewModal(false)}
              variant="contained"
              color="primary"
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Modal for file upload */}
      <Modal
        open={showUploadModal}
        onClose={() => setShowUploadModal(false)}
        className="media_modal"
      >
        <Box sx={{ ...style, width: 500 }}>
          <Typography variant="h6" gutterBottom>
            Upload Files
          </Typography>
          <div className="d-flex flex-column align-items-center justify-content-center gap-3">
            <input
              type="file"
              onChange={handleFileChange}
              accept="image/jpeg, image/png, image/gif, image/webp"
              style={{ marginTop: "10px" }}
              multiple
            />
            <Typography variant="body2" color="textSecondary">
              Supported formats: JPG, PNG, GIF, WEBP. Max file size: 5MB.
            </Typography>
          </div>

          {errorMessage && (
            <Typography color="#ff0000" className="mt-3">
              {errorMessage}
            </Typography>
            // <Typography className="danger">{errorMessage}</Typography>
          )}
          {/* <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}> */}
          <Box className="mt-3 d-flex gap-2 ">
            {/* <Button variant="outlined" onClick={() => setShowUploadModal(false)}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleUpload}>
              Upload
            </Button> */}

            <button
              className="btn btn-sm btn-outline-dark"
              onClick={() => setShowUploadModal(false)}
            >
              {" "}
              Cancel{" "}
            </button>
            <button className="btn btn-sm btn-dark" onClick={handleUpload}>
              Upload
            </button>
          </Box>
        </Box>
      </Modal>
    </Layout>
  );
};

export default FileManager;
