import React, { useEffect } from "react";
import Layout from "../../components/layouts/Layout";
import { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { Formik, Form, Field } from "formik";
import { addCategoryValidation } from "../../validations/addCategoryValidation";
import { addCategory } from "../../features/category/categorySlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { FaArrowLeft } from "react-icons/fa";
import Cookies from "js-cookie";
import { fetchAllCategories } from "../../features/category/categorySlice";
import { getSelectedMarket } from "../../features/market/marketSlice";
import { useSelector } from "react-redux";
import ImageUploader from "../../components/imageUploader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, } from "@fortawesome/free-solid-svg-icons";

const AddCategory = () => {
  const [loading, setLoading] = useState(false);
  const [error, seError] = useState([]);
  const [categories, setCategories] = useState([]);
  const [catOption, setCatOption] = useState([]);
  const [marketSlug, setmarketSlug] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedMarket = useSelector(getSelectedMarket);
  // console.log(selectedMarket)
  const initialValues = {
    name: "",
    description: " ",
    parent_category: " ",
    banner: " ",
    bannerFileId: " ",
    icon: " ",
    iconFileId: " ",
    slide_show: [],
    slide_show_fileId: [],
    tag: "",
  };
  const catOptions = [];

  const fetchCategory = async () => {
    const res = await dispatch(fetchAllCategories({ selectedMarket })).unwrap();
    console.log(res);
    setCategories(res);
    res?.map((cat) => {
      catOptions.push({ label: cat.name, value: cat.id });
    });

    setCatOption(catOptions);
  };

  useEffect(() => {
    fetchCategory();

    const savedMarket = Cookies.get("selectedMarket");
    setmarketSlug(savedMarket);
  }, [dispatch, selectedMarket]);

  const handleSubmit = async (values) => {
    console.log("values", values);

    try {
      values.market = marketSlug;

      const res = await dispatch(addCategory(values)).unwrap();

      toast.success("Category created successfully!");
      // navigate('/category')
    } catch (error) {
      toast.error("Error Saving Category ==> " + error);
    }
  };

  const goBack = () => {
    window.history.back();
  };
  return (
    <Layout>
      <div className="col-12 stretch-card container-fluid">
        <div
          style={{
            marginBottom: "30px",
            display: "flex",
            alignItems: "center",
            gap: "20px",
            color: "#D93D6E",
          }}
        >
          <FaArrowLeft size={20} cursor="pointer" onClick={goBack} />
          <h2 className="heading"> Add Category</h2>
        </div>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={addCategoryValidation}
            onSubmit={(values) => {
              console.log(values);

              handleSubmit(values);
            }}
          >
            {({ values, errors, setFieldValue }) => (
              <Form>
                <div className="card">
                  <div className="card-body">
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Category Name
                      </label>
                      <Field
                        type="name"
                        className="form-control"
                        id="name"
                        name="name"
                        aria-describedby="nameHelp"
                      ></Field>
                      {errors.name && (
                        <small className="text-danger">{errors.name}</small>
                      )}
                    </div>

                    <div className="mb-4">
                      <label htmlFor="description" className="form-label">
                        Description
                      </label>
                      <Field
                        as="textarea"
                        type="text"
                        className="form-control"
                        id="description"
                        name="description"
                      ></Field>
                      {errors.description && (
                        <small className="text-danger">
                          {errors.description}
                        </small>
                      )}
                    </div>

                    <div className="mb-4">
                      <label htmlFor="parent_category" className="form-label">
                        Parent Category:
                      </label>
                      <Field
                        as="select"
                        name="parent_category"
                        id="parent_category"
                        className="form-control"
                        onChange={(event) => {
                          const { value } = event.target;
                          // Explicitly handle the 'None' option by setting the value to null or undefined
                          const realValue = value === "" ? null : value;
                          setFieldValue("parent_category", realValue);
                        }}
                      >
                        <option value="">None</option>
                        {catOption.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Field>
                      {errors.parent_category && (
                        <small className="text-danger">
                          {errors.parent_category}
                        </small>
                      )}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Category Tag (Visible on Category List)
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="tag"
                        name="tag"
                        aria-describedby="nameHelp"
                      ></Field>
                      {errors.tag && (
                        <small className="text-danger">{errors.tag}</small>
                      )}
                    </div>

                    <div className="mb-4">
                      <Field
                        type="checkbox"
                        className="form-check-input"
                        id="is_featured"
                        name="is_featured"
                      ></Field>
                      <label
                        htmlFor="is_featured"
                        className="form-check-label ms-1"
                      >
                        Show in Collection List
                      </label>
                      {errors.is_featured && (
                        <small className="text-danger">
                          {errors.is_featured}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="mb-4  w-25 ">
                      <div className="d-flex flex-column">
                        <label htmlFor="banner" className="form-label">
                          Banner:
                        </label>
                        <ImageUploader
                          setFieldValue={setFieldValue}
                          imageType={"banner"}
                          fileType={"bannerFileId"}
                          className="col"
                        />
                      </div>

                      {/* Show selected image */}
                      {typeof values.banner == "string" && (
                        <img
                          src={`${process.env.REACT_APP_MEDIA_URL}${values.banner}`}
                          alt=""
                          height="100px"
                        />
                      )}
                      {values.banner instanceof File && (
                        <img
                          src={URL.createObjectURL(values.banner)}
                          alt="Selected Banner"
                          height="100px"
                        />
                      )}

                      {errors.banner && (
                        <small className="text-danger">{errors.banner}</small>
                      )}
                      {/* </div> */}

                      <div className="iconImage ">
                        {values.icon instanceof File && (
                          <div>
                            <img
                              src={URL.createObjectURL(values.icon)}
                              height="100px"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="mb-4 w-25 ms-4">
                    <div className="d-flex flex-column">
                      <label htmlFor="banner" className="form-label">
                        Icon image
                      </label>
                      <ImageUploader
                        setFieldValue={setFieldValue}
                        imageType={"icon"}
                        fileType={"iconFileId"}
                      />
                    </div>

                    {/* Show selected image */}
                    {typeof values.icon == "string" && (
                      <img
                        src={`${process.env.REACT_APP_MEDIA_URL}${values.icon}`}
                        alt=""
                        height="100px"
                      />
                    )}
                    {values.banner instanceof File && (
                      <img
                        src={URL.createObjectURL(values.icon)}
                        alt="Selected Banner"
                        height="100px"
                      />
                    )}

                    {errors.icon && (
                      <small className="text-danger">{errors.icon}</small>
                    )}
                    {/* </div> */}
                  </div>
                </div>

                <div className="card">
                  <div className="card-body">
                    <div className="mb-4  ">
                      <div className="d-flex flex-column">
                        <label htmlFor="slide_show" className="form-label">
                          Slideshow images
                        </label>
                        <ImageUploader
                          setFieldValue={setFieldValue}
                          imageType="slide_show"
                          values={values}
                          allowMultiple={true}
                          fileType={"slide_show_fileId"}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          flexWrap: "wrap",
                        }}
                      >
                        {Array.isArray(values.slide_show) &&
                          values.slide_show.map((file, index) => (
                            <div
                              key={index}
                              className="col-md-3 mb-2"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                              }}
                            >
                              {/* Check if the file is an object (uploaded from system) or a string (filename from file manager) */}
                              {typeof file === "string" ? (
                                // If it's a filename, display the image using the URL from the environment variable
                                <img
                                  src={`${process.env.REACT_APP_MEDIA_URL}${file}`}
                                  height="230px"
                                  width="250px"
                                  alt="Selected"
                                />
                              ) : (
                                // If it's a file object, use URL.createObjectURL to preview the uploaded file
                                <img
                                  src={URL.createObjectURL(file)}
                                  height="150px"
                               
                                  alt="Selected"
                                />
                              )}

                              <button
                                type="button"
                                className="btn btn-sm mt-2 text-danger"
                                onClick={() => {
                                  setFieldValue(
                                    "slide_show",
                                    values.slide_show.filter(
                                      (_, i) => i !== index
                                    )
                                  );
                                }}
                                // style={{
                                //   backgroundColor: "transparent",
                                //   border: "1px solid #D93D6E",
                                // }}
                              >
                                  <span className="me-1"><FontAwesomeIcon
                                                  icon={faXmark}
                                                /></span>
                                Remove
                              </button>
                            </div>
                          ))}
                          
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>

                <button
                  className="btn  w-100 py-8 fs-4 mb-4 rounded-2"
                  type="submit"
                  style={{ backgroundColor: "#D93D6E", color: "white" }}
                >
                  {loading ? "Loading..." : "Create Category"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Layout>
  );
};

export default AddCategory;
