import { useEffect, useState } from "react";
import Layout from "../../components/layouts/Layout";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fetchAllwidget, updateWidgetStatus } from "../../features/widget/homeWidgetSlice";
import WidgetCard from "./components/WidgetCard";  // Correct import path
import { getSelectedMarket } from "../../features/market/marketSlice";

const debugMode = process.env.REACT_APP_DEBUG || "";

const HomeWidget = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [homeWidgets, setHomeWidgets] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const selectedMarket = useSelector(getSelectedMarket);
  console.log(selectedMarket)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchWidget = async () => {
    const res = await dispatch(fetchAllwidget({ selectedMarket })).unwrap();
    setHomeWidgets(res);
  };

  useEffect(() => {
    fetchWidget();
  }, [dispatch, selectedMarket]);

  const handleToggle = (id, isActive) => {
    const confirmMessage = `Are you sure you want to ${isActive ? "activate" : "deactivate"} this widget?`;
    if (window.confirm(confirmMessage)) {
      dispatch(updateWidgetStatus({ id, is_active: isActive }))
        .unwrap()
        .then(() => {
          fetchWidget();
          toast.success(`Widget ${isActive ? "activated" : "deactivated"} successfully!`);
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  const handleOpen = (id) => {
    setDeleteId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    <Layout>
      <div className="d-flex justify-content-between align-items-end">
        <h2 className="heading">Home Widget</h2>

        <h5 className="fw-bolder mb-4">Total Widgets: {homeWidgets?.length}</h5>

      </div>
      <div className='row'>
        <div className='col d-inline-flex justify-content-end gap-2'>
        {debugMode && <Link
              to={`/addWidget`}
              className="btn mb-4"
              style={{ backgroundColor: "#D93D6E", color: "white", width: "200px" }}
            >
              Add Widget
            </Link>}
        </div>
      </div>
      <div className="col-12 stretch-card container-fluid">
      
        <div className="d-flex flex-wrap">
          {homeWidgets.map(widget => (
            <WidgetCard key={widget.id} widget={widget} onToggle={handleToggle} />
          ))}
        </div>
      </div>
      {/* {debugMode && <div><pre>{JSON.stringify(homeWidgets, null, 2)}</pre></div>} */}
    </Layout>
  );
};

export default HomeWidget;
