import React, { useEffect, useState } from 'react';
import Layout from '../../components/layouts/Layout';
import DataTable from 'react-data-table-component';
import { Link, useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { fetchAllReturns } from '../../features/Return/returnSlice';
import { useSelector } from 'react-redux';
import { getSelectedMarket } from '../../features/market/marketSlice';

const Returns = () => {
  const selectedMarket = useSelector(getSelectedMarket);
  const [returns, setReturns] = useState([]);
  const navigate = useNavigate();
  const columns = [
    {
      name: <b>Product Name</b>,
      selector: row => row.item.name, 
      sortable: true,
    },
    {
      name: <b>Return Quantity</b>,
      selector: row => row.return_qty, 
      sortable: true,
    },
    {
      name: <b>Customer Name</b>,
      selector: row => `${row.customer_first_name}`, 
      sortable: true,
    },
    {
      name: <b>Reason</b>,
      selector: row => row.reason, 
      sortable: true,
    },
    {
      name: <b>Action</b>,
      cell: row => (
        <button 
          className="btn btn-dark" 
          onClick={() => handleDetails(row._id)} 
        >
        Details
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const dispatch = useDispatch();

  const fetchReturns = async () => {
    const res = await dispatch(fetchAllReturns({selectedMarket})).unwrap(); 
    // console.log(res)
    setReturns(res);
  };

  useEffect(() => {
    fetchReturns();
  }, [dispatch,selectedMarket]);

  // Function to handle setting the status of a return
  const handleDetails = async (id) => {
    navigate(`/returnDetails/${id}`);
  };

  return (
    <Layout>
      <h2 className="heading ms-3">Returns</h2>
      <div className="col-12 stretch-card container-fluid">
        <div className='d-flex justify-content-end gap-2 mb-3'>
          {/* <Link
            to={`/addReturn`} // Link to add new return
            className="btn ms-1"
            style={{ backgroundColor: '#D93D6E', color: "white", width: "200px" }}
          >
            Add Return
          </Link> */}
          <input
            type="text"
            className="w-25 form-control "
            placeholder="Search Returns"
            // value={search} // If you want to add search functionality
            // onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="table-responsive">
          <DataTable
            columns={columns}
            data={returns}
            fixedHeader
            pagination
            highlightOnHover
            onRowClicked={(row) => console.log('Row clicked:', row)}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Returns;
