

import axiosInstance from "../utils/axiosInstance";

const API_URL = `${process.env.REACT_APP_API_URL}/api/admin/admin_user`;


export const fetchAllAdminUsers = async () => {
    try {
    const response = await axiosInstance.get(`${API_URL}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching admin users:", error);
      throw error;
    }
  };