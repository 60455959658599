import { Formik, Form, Field } from 'formik';
import MainLogo from "../assets/images/logos/sthani_logo.png";
import AuthService from '../services/auth.service';
import { toast } from "react-toastify";
import { validationSchema_reset_password } from '../validations/ResetPassword';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
    const navigate = useNavigate();
    
    const handleResetPassword = async (values) => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token'); 
      
        if (!token) {
          toast.error('Invalid or missing reset token.');
          return;
        }
      
        try {
          const response = await AuthService.resetPassword(values.password, token); 
          console.log("response",response)
          toast.success('Password has been reset successfully!');
          if (response) {
            navigate(`/login`);
        }
        } catch (err) {
          console.error(err);
          toast.error('Failed to reset password. Please try again.');
        }
      };


 

    return (
        <div className="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed">
            <div className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
                <div className="d-flex align-items-center justify-content-center w-100">
                    <div className="row justify-content-center w-100">
                        <div className="col-md-8 col-lg-6 col-xxl-3">
                            <div className="card mb-0">
                                <div className="card-body">
                                    <a href="/" className="text-nowrap logo-img text-center d-block py-3 w-100">
                                        <img src={MainLogo} width="120px" alt="Main Logo" />
                                    </a>
                                    <p className="text-center">Reset Password</p>
                                    <Formik
                                        initialValues={{ password: '', confirmPassword: '' }}
                                        validationSchema={validationSchema_reset_password}
                                        onSubmit={(values) => handleResetPassword(values)}
                                    >
                                        {({ errors }) => (
                                            <Form>
                                                <div className="mb-3">
                                                    <label htmlFor="password" className="form-label">Password</label>
                                                    <Field
                                                        type="password"
                                                        className="form-control"
                                                        id="password"
                                                        name="password"
                                                        placeholder="Enter your new password"
                                                    />
                                                    {errors.password && <small className="text-danger">{errors.password}</small>}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                                                    <Field
                                                        type="password"
                                                        className="form-control"
                                                        id="confirmPassword"
                                                        name="confirmPassword"
                                                        placeholder="Confirm your new password"
                                                    />
                                                    {errors.confirmPassword && <small className="text-danger">{errors.confirmPassword}</small>}
                                                </div>
                                                <button
                                                    className="btn btn-primary w-100 py-8 fs-4 mb-4 rounded-2"
                                                    type="submit"
                                                >
                                                    Reset Password
                                                </button>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
