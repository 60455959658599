import React, { useEffect, useState } from 'react';
import Layout from '../../components/layouts/Layout';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { fetchAllMarket } from '../../features/market/marketSlice';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose  } from '@fortawesome/free-solid-svg-icons';

const Market = () => {

  const [markets, setMarkets] = useState([]);
  
  const columns = [
    {
      name: <b>Flag</b>,
      selector: row => <img
      src={`${process.env.REACT_APP_MEDIA_URL}${row.flag}`} 
      alt={row.name}
      width="30"
    />,
      sortable: false,
      width: '100px',
    },
    {
      name:<b>Name</b>,
      selector: row =><span className='text-capitalize'> {`${row.name}`} </span>,
      sortable: true,
    },
    {
      name: <b>Currency (name)</b>,
      selector: row => row.currency.name,
      sortable: true,
    },
    {
      name: <b>Status</b>,
      selector: row => row.status === 1 ?<>Active  <FontAwesomeIcon icon={faCheck} size="sm" color="#20c997" /></> : `${<FontAwesomeIcon icon={faClose} size="sm" color="#ff0000 " />}'Inactive'`,
      sortable: true,
    },
    {
      name: <b>Edit</b>,
      cell: row => (
        <Link to={`/editMarket/${row._id}`} className="btn btn-dark">
          Edit
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];


  const dispatch = useDispatch();
  var status
  const fetchMarket = async () => {
    const res = await dispatch(fetchAllMarket({status})).unwrap();
    // console.log(res)
    setMarkets(res.data);
   
  };

  
useEffect(() => {
fetchMarket();
}
, [dispatch]);





  return (
    <Layout>
      <h2 className="heading ms-3">Markets</h2>
      <div className="col-12 stretch-card container-fluid">
        <div className='d-flex justify-content-end gap-2 mb-3' >
          <Link
            to={`/Addmarket`}
            className="btn px-4 btn-primary ms-1"
            
          >
            Add market
          </Link>
          <input
            type="text"
            className="w-25 form-control"
            placeholder="Search Markets"
            // value={search}
            // onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="table-responsive">
          <DataTable
            columns={columns}
            data={markets} 
            fixedHeader
            pagination
            highlightOnHover
            onRowClicked={(row) => console.log('Row clicked:', row)}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Market;
