import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

import { Link, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from 'xlsx';
import { fetchAllAdminUsers } from "../services/adminUser.service";
import Layout from "../components/layouts/Layout";


const mediaFolder = process.env.REACT_APP_MEDIA_URL;

const AdminUsers = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [admins, setAdmins] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredAdmins, setFilteredAdmins] = useState([]);
  const dispatch = useDispatch();

  const fetchAdmins = async () => {
    try {
      const res = await fetchAllAdminUsers(); 
      setAdmins(res.data);
      setFilteredAdmins(res.data);
    } catch (error) {
      console.error("Error fetching admin users:", error);
    }
  };

  useEffect(() => {
    fetchAdmins();
  }, []);

  useEffect(() => {
    const result = admins.filter((admin) => {
      const searchTerm = search.toLowerCase();
      return (
        (admin.name && admin.name.toLowerCase().includes(searchTerm)) ||
        (admin.email && admin.email.toLowerCase().includes(searchTerm))
      );
    });
    setFilteredAdmins(result);
  }, [search, admins]);

  const exportToExcel = () => {
    const data = admins.map(admin => ({
      'Name': admin.name,
      'Email': admin.email,
      'Role': admin.role,
      'Registered On': admin.createdAt,
      'Profile Picture': `${mediaFolder}/profile/${admin.profile_pic || "default.png"}`,
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Admins');

    XLSX.writeFile(workbook, 'admins.xlsx');
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      grow: 1.5,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      grow: 1.5,
    },
    // {
    //   name: "Role",
    //   selector: (row) => row.role,
    //   sortable: true,
    //   grow: 1,
    // },
    {
      name: "Registered On",
      selector: (row) => new Date(row.createdAt).toLocaleDateString(),
      sortable: true,
      grow: 1.5,
    },
    // {
    //   name: "Action",
    //   right: true,
    //   grow: 1,
    //   cell: (row) => (
    //     <div>
    //       <Link to={`/admin/${row._id}`}>
    //         <span style={{ color: '#D93D6E' }}>View Details</span>
    //       </Link>
    //     </div>
    //   ),
    // },
  ];

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    <Layout>
      <h2 className="heading ms-3">Admin Users</h2>
      <div className="col-12 stretch-card container-fluid">
        <div className="table-responsive">
          <DataTable
            columns={columns}
            data={filteredAdmins}
            fixedHeader
            pagination
            highlightOnHover
            subHeader
            subHeaderComponent={
              <>
                <button onClick={exportToExcel} className="me-3 btn btn-dark">Export to Excel</button>
                <input
                  type="text"
                  className="w-25 form-control"
                  placeholder="Search Admin Users"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </>
            }
          />
        </div>
      </div>
    </Layout>
  );
};

export default AdminUsers;
