import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message/messageSlice";
import MediaService from "../../services/media.service";




let initialState = {
    medias: [],
    media: {},

    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 1,
};




export const fetchAllMedia = createAsyncThunk(
    "media/fetchAllMedia",
    async ({data},thunkAPI) => {
        try {
            const datas = await MediaService.getmedia(data);
            console.log(datas);
            return datas;
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);



const MediaSlice = createSlice({
    name: 'media',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllMedia.fulfilled, (state, action) => {
                state.medias = action.payload;
            })
           
    }
});



export default MediaSlice.reducer;







