import ImageUploader from "../../../components/imageUploader";

const CustomFileInput = ({ field,filenameid, form,...props }) => {

  const handleChange = (event) => {
    const file = event.currentTarget.files[0];
    console.log(field.name)
    form.setFieldValue(field.name, file);
  };

  // console.log(filenameid)
  // console.log(form)

  return (
    // <input
    //   type="file"

    //   onChange={handleChange}
    //   {...props}
    // />

<ImageUploader  setFieldValue={form.setFieldValue}  imageType={field.name}   fileType={filenameid}/>
  );
};

export default CustomFileInput  