import React, { useState, useEffect } from "react";
import { Modal, Box, Button, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { fetchAllMedia } from "../features/media/mediaSlice";
import MediaService from "../services/media.service";

const ImageUploader = ({
  setFieldValue,
  imageType,
  fileType,
  allowMultiple = false,
  values,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [files, setFiles] = useState([]); // All files fetched
  const [visibleFiles, setVisibleFiles] = useState([]); // Files currently displayed
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFileIds, setSelectedFileIds] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();
  const allowedExtensions = ["jpeg", "png", "gif", "jpg", "webp"];
  const maxSize = 5 * 1024 * 1024;

  const fetchFiles = async () => {
    const data = {
      searchTerm: searchQuery,
      fileType: "",
      usedIn: "",
      minSize: "",
      maxSize: "",
    };

    try {
      const res = await dispatch(fetchAllMedia({ data })).unwrap();
      if (res.files.length > 0) {
        setFiles(res.files); // Save all files
        setVisibleFiles(res.files.slice(0, 50)); // Show first 50 images initially
      }
    } catch (error) {
      console.error("Error fetching media:", error);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, [searchQuery]);

  const handleFileUpload = async (event) => {
    setErrorMessage("");
    const uploadedFiles = Array.from(event.target.files);

    const validFiles = uploadedFiles.filter((file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        setErrorMessage("Invalid file type. Please select image files only.");
        return false;
      }
      if (file.size > maxSize) {
        setErrorMessage(`File size exceeds ${maxSize / 1024 / 1024} MB.`);
        return false;
      }
      return true;
    });

    if (validFiles.length > 0) {
      const uploadPromises = validFiles.map(async (file) => {
        const formData = new FormData();
        formData.append("media", file);

        try {
          await MediaService.uploadMedia(formData);
          fetchFiles();
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      });

      await Promise.all(uploadPromises);
    }
    setSelectedFiles(validFiles);
  };

  const handleSelectFile = (file) => {
    if (allowMultiple) {
      setSelectedFiles((prev) => [...prev, file.diskname]);
      setSelectedFileIds((prev) => [...prev, file._id]);
    } else {
      setFieldValue(imageType, file.diskname);
      setFieldValue(fileType, file._id);
      setIsModalOpen(false);
    }
  };

  const handleConfirmSelection = () => {
    const currentMedia = values[imageType] || [];
    const currentFileIds = values[fileType] || [];

    const updatedMedia = allowMultiple
      ? [...currentMedia, ...selectedFiles]
      : [selectedFiles[0]];
    const updatedFileIds = allowMultiple
      ? [...currentFileIds, ...selectedFileIds]
      : [selectedFileIds[0]];

    setFieldValue(imageType, updatedMedia);
    setFieldValue(fileType, updatedFileIds);

    setSelectedFiles([]);
    setSelectedFileIds([]);
    setIsModalOpen(false);
  };

  const handleClose = () => {
    setSelectedFiles([]);
    setSelectedFileIds([]);
    setIsModalOpen(false);
  };

  const loadMoreFiles = () => {
    const currentVisibleCount = visibleFiles.length;
    const nextBatch = files.slice(
      currentVisibleCount,
      currentVisibleCount + 20
    );
    if (nextBatch.length > 0) {
      setVisibleFiles((prevVisibleFiles) => [
        ...prevVisibleFiles,
        ...nextBatch,
      ]);
    }
  };

  const modalStyle = {
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    // transform: "translate(-50%, -50%)",
    // width: "90%",
    // maxWidth: "1200px",
    // backgroundColor: "white",
    // boxShadow: 24,
    // padding: 4,
    // borderRadius: "8px",
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setIsModalOpen(true)}
        className="upload-img_btn"
      >
        {allowMultiple ? "Upload Images" : "Upload Image"}
      </Button>

      <Modal
        open={isModalOpen}
        onClose={handleClose}
        className="imgUploader_modal"
      >
        <Box className="box_modal" sx={modalStyle}>
          <div className="w-50">
            <div>
              {/* <Button
              variant="outlined"
              onClick={() => document.getElementById("fileInput").click()}
            >
              {allowMultiple ? "Upload Multiple Images" : "Upload from System"}
            </Button> */}
              <input
                id="fileInput"
                type="file"
                style={{ display: "none" }}
                multiple={allowMultiple}
                onChange={handleFileUpload}
              />
              {errorMessage && (
                <Typography color="error">{errorMessage}</Typography>
              )}
            </div>

            {/* <Typography variant="h6" mt={2} className="headings" >
            Or Select from File Manager
          </Typography> */}
            <input
              type="search"
              className="form-control mt-2"
              placeholder="Search by filename"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          {/* Scrollable container for images */}
          {/* <div
            style={{
              maxHeight: "400px",
              overflowY: "auto",
              overflowX: "hidden", 
              border: "1px solid #ddd",
              borderRadius: "4px",
              padding: "10px",
              marginTop: "20px",
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
              gap: "10px",
            }}
          >
            {visibleFiles.map((file) => (
              <div
                key={file._id}
                onClick={() => handleSelectFile(file)}
                style={{
                  cursor: "pointer",
                  border: selectedFileIds.includes(file._id)
                    ? "2px solid blue"
                    : "1px solid transparent",
                  borderRadius: "4px",
                  padding: "4px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={`${process.env.REACT_APP_MEDIA_URL}${file.diskname}`}
                  alt={file.file_name}
                  style={{
                    width: "100%",
                    height: "100px",
                    objectFit: "cover",
                    borderRadius: "4px",
                  }}
                />
                <Typography
                  variant="body2"
                  style={{ textAlign: "center", marginTop: "4px", wordBreak: "break-word" }}
                >
                  {file.file_name}
                </Typography>
              </div>
            ))}
          </div> */}

          <div className="file-grid-wrapper">
            <div className="add-media mt-4 text-center w-100 ">
              <div className="d-flex justify-content-center align-items-center mb-2 gap-2">
                <button
                  className="btn btn-sm btn-outline-dark"
                  variant="outlined"
                  onClick={() => document.getElementById("fileInput").click()}
                >
                  Add media
                </button>
                <p className="">Add from URL</p>
              </div>
              <span>Drag and drop images and files </span>
            </div>
            <div className="file-grid">
              {visibleFiles.map((file) => (
                <div
                  key={file._id}
                  onClick={() => handleSelectFile(file)}
                  className={`file-item ${
                    selectedFileIds.includes(file._id) ? "selected" : ""
                  }`}
                >
                  <img
                    src={`${process.env.REACT_APP_MEDIA_URL}${file.diskname}`}
                    alt={file.file_name}
                    className="file-image shadow "
                  />
                  <Typography variant="body2" className="file-name">
                    {file.file_name}
                  </Typography>
                </div>
              ))}
            </div>
          </div>

          {visibleFiles.length < files.length && (
            <div className="text-center">
              <Button onClick={loadMoreFiles}>Load More</Button>
            </div>
          )}
          <hr />

          <div className="d-flex justify-content-end mt-3">
            {/* <Button
              variant="contained"
              color="primary"
              onClick={handleConfirmSelection}
              style={{ marginRight: "10px" }}
            >
              Confirm Selection
            </Button>

            
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Close
            </Button> */}

            <button
              type="button"
              class="btn btn-sm btn-primary "
              variant="contained"
              color="primary"
              onClick={handleConfirmSelection}
              style={{ marginRight: "10px" }}
            >
              {" "}
              Confirm Selection
            </button>

            <button
              variant="outlined"
              className="btn btn-sm btn-dark "
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ImageUploader;
