import axiosInstance from "../utils/axiosInstance";

const API_URL = `${process.env.REACT_APP_API_URL}/api/admin/`;


const getFeedaback = async () => {
    const res = await axiosInstance.get(API_URL + "feedback",)

    return res.data;

}




const FeedabackService = {
    getFeedaback

   
}

export default FeedabackService;