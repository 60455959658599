import React from 'react';

/**
 * Returns a JSX element representing the order status as a Bootstrap pill.
 * @param {string} status - The order status.
 * @returns {JSX.Element} A span element with the appropriate Bootstrap classes.
 */
export const orderStatusFormat = (status) => {
  let colorClass = '';

  switch (status.toLowerCase()) {
    case 'unfulfilled':
      colorClass = 'bg-warning border border-warning-subtle text-warning-emphasis ';  // Yellow for attention
      break;
    case 'fulfilled':
      colorClass = 'bg-primary border border-primary-subtle text-white';            // Blue for in-progress
      break;
    case 'shipped':
      colorClass = 'bg-info-subtle border border-info-subtle text-info-emphasis';     // Light blue for in transit
      break;
    case 'delivered':
      colorClass = 'bg-success border border-sucess-subtle text-white';            // Green for completed
      break;
    case 'cancelled':
      colorClass = 'bg-danger-subtle text-danger-emphasis';             // Red for cancelled
      break;
    default:
      colorClass = 'bg-info-subtle border border-info-subtle text-info-emphasis';          // Grey for undefined status
      break;
  }

  return <span style={{fontSize:"0.75rem" }} className={`badge ${colorClass}`}>{status.toUpperCase()}</span>;
};

export const paymentStatusFormat = (status) => {
    let colorClass = '';
  
    switch (status.toLowerCase()) {
      case 'pending':
        colorClass = ' bg-warning border border-warning-subtle text-warning-emphasis';  // Yellow for attention
        break;
      case 'paid':
        colorClass = 'bg-success border border-success-subtle  text-white';            // Blue for in-progress
        break;
      case 'failed':
        colorClass = 'bg-danger-subtle border border-danger-subtle text-danger-emphasis ';     // Light blue for in transit
        break;
     
      default:
        colorClass = 'bg-info-subtle border border-info-subtle text-info-emphasis';          // Grey for undefined status
        break;
    }
  
    return <span  style={{fontSize:"0.75rem" }}  className={ `badge ${colorClass}`}>{status.toUpperCase()}</span>;
  };
  