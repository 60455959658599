import api from './api'; // Adjust the path accordingly

const API_URL = `${process.env.REACT_APP_API_URL}/api/v1/auth/`;

//only for testing
const API_URL_a = `${process.env.REACT_APP_API_URL}/api/store/auth/register_google`;




const setTokens = (data) => {
  localStorage.setItem("user", JSON.stringify(data));
  localStorage.setItem("token", data.tokens.access.token);
  localStorage.setItem("refreshToken", data.tokens.refresh.token);
};

const register = async (username, email, password) => {
  try {
    const response = await api.post(API_URL + "register", {
      name: username,
      email,
      password,
    });

    if (response.data.user) {
      setTokens(response.data);
    }

    return response.data;
  } catch (error) {
    console.error("Registration error:", error);
    throw error;
  }
};

const login = async (email, password) => {
  try {
    const response = await api.post(API_URL + "login", {
      email,
      password,
    });

    if (response.data.user) {
      setTokens(response.data);
    }

    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};

const logout = async () => {
  const refreshToken = localStorage.getItem("refreshToken");

  try {
    await api.post(API_URL + "logout", { refreshToken });
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    console.log("Logout successful");
  } catch (error) {
    console.error("Logout error:", error);
    // Even if logout request fails, remove tokens from local storage
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
  }
};

const refreshToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (!refreshToken) throw new Error("No refresh token available");

  try {
    const response = await api.post(API_URL + "refresh-token", { refreshToken });
    if (response.data.user) {
      setTokens(response.data);
    }
    return response.data;
  } catch (error) {
    console.error("Refresh token error:", error);
    throw error;
  }
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};



// New Google Login function
const googleLogin = async (tokenId) => {
  try {
    const response = await api.post(API_URL_a, {
      idToken: tokenId,
    });
console.log("response.data",response.data)
    if (response.data.user) {
      setTokens(response.data);
    }

    return response.data;
  } catch (error) {
    console.error("Google Login error:", error);
    throw error;
  }
};

// for send the link for forgot_password
const forgotPassword = async (email) => {
  try {
    const response = await api.post(`${API_URL}forgot-password`,{ email });
    console.log("Forgot password response:", response);
    return response.data;
  } catch (error) {
    console.error("Forgot password error:", error);
    throw error;
  }
};



const resetPassword = async (password, token) => {
  try {
    const response = await api.post(`${API_URL}reset-password?token=${token}`, { password });
    return response.data;
  } catch (error) {
    console.error("Reset Password error:", error);
    throw error;
  }
};
const AuthService = {
  register,
  login,
  logout,
  refreshToken,
  getCurrentUser,
  googleLogin,
  forgotPassword,
  resetPassword
  

  
};

export default AuthService;
