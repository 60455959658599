import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../features/auth/authSlice";
import { Formik, Form, Field } from "formik";
import {
  loginValidation,
  marketValidation,
} from "../validations/loginValidation";
import MainLogo from "../assets/images/logos/sthani_logo.png";
import { fetchAllMarket } from "../features/market/marketSlice";
import Cookies from "js-cookie";

const initialValues = {
  email: "",
  password: "",
};

const Login = () => {
  const { loading, error, user } = useSelector((state) => state.auth);
  const [ishowDropdown, setishowDropdown] = useState(false);
  const [markets, setMarkets] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const status = 1;

  const fetchMarket = async () => {
    const res = await dispatch(fetchAllMarket({ status })).unwrap();
    setMarkets(res.data);
  };

  const userLogin = async (values) => {
    dispatch(login(values)).then((result) => {
      if (result.payload) {
        fetchMarket();
        setishowDropdown(true);
      }
    });
  };

  const handleMarketSelection = (values) => {
    Cookies.set("selectedMarket", values.market, { expires: 7 });
    navigate("/dashboard");
  };

  return (
    <div
      className="page-wrapper"
      id="main-wrapper"
      data-layout="vertical"
      data-navbarbg="skin6"
      data-sidebartype="full"
      data-sidebar-position="fixed"
      data-header-position="fixed"
    >
      <div className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center justify-content-center w-100">
          <div className="row justify-content-center w-100">
            <div className="col-md-8 col-lg-6 col-xxl-3">
              <div className="card mb-0">
                <div className="card-body">
                  <Link
                    to="/"
                    className="text-nowrap logo-img text-center d-block py-3 w-100"
                  >
                    <img src={MainLogo} width="120px" alt="Main Logo" />
                  </Link>
                  <p className="text-center">
                    {ishowDropdown
                      ? "Choose the market"
                      : "Log in to your dashboard"}
                  </p>
                  {!ishowDropdown ? (
                    <Formik
                      initialValues={initialValues}
                      validationSchema={loginValidation}
                      onSubmit={(values) => {
                        userLogin(values);
                      }}
                      validateOnBlur={true}
                      validateOnChange={false}
                    >
                      {({ errors }) => (
                        <Form>
                          <div className="mb-3">
                            <label htmlFor="email" className="form-label">
                              Email
                            </label>
                            <Field
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              aria-describedby="emailHelp"
                            />
                            {errors.email && (
                              <small className="text-danger">
                                {errors.email}
                              </small>
                            )}
                          </div>
                          <div className="mb-4">
                            <label htmlFor="password" className="form-label">
                              Password
                            </label>
                            <Field
                              type="password"
                              className="form-control"
                              id="password"
                              name="password"
                            />
                            {errors.password && (
                              <small className="text-danger">
                                {errors.password}
                              </small>
                            )}
                          </div>
                          <div className="d-flex align-items-center justify-content-between mb-4">
                            <Link
                              to="/forgot_password"
                              className="text-primary fw-bold"
                            >
                              Forgot Password?
                            </Link>
                          </div>
                          <button
                            className="btn btn-primary w-100 py-8 fs-4 mb-4 rounded-2"
                            type="submit"
                          >
                            {loading ? "Loading..." : "Sign In"}
                          </button>
                          {error && (
                            <div className="alert alert-danger" role="alert">
                              {error}
                            </div>
                          )}
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    <Formik
                      initialValues={{ market: "" }}
                      validationSchema={marketValidation}
                      onSubmit={(values) => handleMarketSelection(values)}
                    >
                      {({ errors }) => (
                        <Form>
                          <div className="mb-3">
                            <label htmlFor="market" className="form-label">
                              Select Market
                            </label>
                            <Field
                              as="select"
                              className="form-select"
                              id="market"
                              name="market"
                            >
                              <option value="">-- Choose Market --</option>
                              {markets.map((market) => (
                                <option key={market._id} value={market.slug}>
                                  {market.name.charAt(0).toUpperCase() +
                                    market.name.slice(1)}
                                </option>
                              ))}
                            </Field>
                            {errors.market && (
                              <small className="text-danger">
                                {errors.market}
                              </small>
                            )}
                          </div>
                          <button
                            className="btn btn-primary w-100 py-8 fs-4 mb-4 rounded-2"
                            type="submit"
                          >
                            Select Market
                          </button>
                        </Form>
                      )}
                    </Formik>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
