import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message/messageSlice";
import MarketService from "../../services/market.service";

let initialState = {
    markets: [],
    market: {},
    selectedMarket: '', // New field to store selected market in Redux
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 1,
};

// Fetch all markets
export const fetchAllMarket = createAsyncThunk(
    "market/fetchAllMarket",
    async ({status},thunkAPI) => {
        try {
            const data = await MarketService.getmarket(status);
            console.log(data);
            return data;
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

// Create new market
export const createMarket = createAsyncThunk(
    "market/createMarket",
    async (values, thunkAPI) => {
        try {
            const data = await MarketService.saveMarket(values);
            console.log(data);
            return data;
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

// Fetch market by ID
export const fetchMarketById = createAsyncThunk(
    "market/fetchMarketById",
    async (id, thunkAPI) => {
        try {
            const data = await MarketService.getmarketByid(id);
            return data;
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

// Update market by ID
export const UpdateMarketById = createAsyncThunk(
    "market/UpdateMarketById",
    async (values, thunkAPI) => {
        try {
            const data = await MarketService.EditMarket(values);
            console.log(data);
            return data;
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

const MarketSlice = createSlice({
    name: 'markets',
    initialState,
    reducers: {
        // Reducer for setting the selected market
        setSelectMarket: (state, action) => {
            state.selectedMarket = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllMarket.fulfilled, (state, action) => {
                state.markets = action.payload;
            })
            .addCase(fetchMarketById.fulfilled, (state, action) => {
                state.market = action.payload; // Store market fetched by ID
            });
    }
});

// Export the actions
export const { setSelectMarket } = MarketSlice.actions;

// Selector to get all markets
export const getAllMarkets = (state) => state.market.markets;

// Selector to get selected market
export const getSelectedMarket = (state) => state.market.selectedMarket;

export default MarketSlice.reducer;
