import * as Yup from 'yup'

export const loginValidation = Yup.object({
    email: Yup.string().email("please enter valid email").required("please enter email"),
    password: Yup.string().min(8, "password must be at least 8 characters").required("please enter password")
})


export   const marketValidation = Yup.object().shape({
    market: Yup.string().required('Please select a market'),
  });
